import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardContent, Avatar, Chip, Button, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';

const HotelsOverzicht = () => {
  const [hotels, setHotels] = useState([]);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    fetchHotels();
  }, []);

  const fetchHotels = async () => {
    try {
      const hotelsQuery = query(collection(db, 'users'), where('role', '==', 'hotel'));
      const hotelsSnapshot = await getDocs(hotelsQuery);
      const hotelsData = await Promise.all(hotelsSnapshot.docs.map(async (doc) => {
        const hotelData = { id: doc.id, ...doc.data() };
        const shiftsQuery = query(collection(db, 'diensten'), where('hotelId', '==', doc.id), where('status', '==', 'pending'));
        const shiftsSnapshot = await getDocs(shiftsQuery);
        hotelData.pendingShifts = shiftsSnapshot.size;
        hotelData.revenue = calculateRevenue(shiftsSnapshot.docs, hotelData.rate);
        return hotelData;
      }));
      setHotels(hotelsData);
    } catch (error) {
      console.error('Fout bij het ophalen van hotelgegevens:', error);
    }
  };

  const calculateRevenue = (shifts, hotelRate) => {
    return shifts.reduce((total, shift) => {
      const shiftData = shift.data();
      const duration = (shiftData.end.toDate() - shiftData.start.toDate()) / (1000 * 60 * 60);
      return total + (duration * hotelRate);
    }, 0);
  };

  const handleBulkAccept = (hotelId) => {
    // Implementeer bulk acceptatie logica
    console.log(`Bulk accepteren voor hotel ${hotelId}`);
  };

  const getHotelColor = (hotelId) => {
    const colors = [
      '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8',
      '#F06292', '#AED581', '#7986CB', '#4DB6AC', '#FFD54F'
    ];
    return colors[parseInt(hotelId, 16) % colors.length];
  };

  const visibleHotels = expanded ? hotels : hotels.slice(0, 3);

  return (
    <Box>
      <Grid container spacing={3}>
        {visibleHotels.map((hotel) => (
          <Grid item xs={12} md={6} lg={4} key={hotel.id}>
            <Card sx={{ 
              borderRadius: '16px',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar src={hotel.companyLogoUrl} alt={hotel.name} sx={{ mr: 2 }} />
                    <Typography variant="h6">{hotel.name}</Typography>
                  </Box>
                  <Chip 
                    label={hotel.hasNotes ? "Heeft notities" : "Geen notities"} 
                    color={hotel.hasNotes ? "primary" : "default"} 
                    size="small"
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" color="text.secondary">Aantal diensten: {hotel.pendingShifts}</Typography>
                  <Typography variant="h5">€{hotel.revenue.toFixed(2)}</Typography>
                  <Typography variant="body2" color="text.secondary">Verwachte omzet</Typography>
                </Box>
                <Box sx={{ height: 100, mb: 2 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart data={[
                      { name: 'Jan', value: 400 },
                      { name: 'Feb', value: 300 },
                      { name: 'Mar', value: 600 },
                      { name: 'Apr', value: 800 },
                      { name: 'May', value: 500 },
                      { name: 'Jun', value: 700 },
                      { name: 'Jul', value: 900 },
                    ]}>
                      <defs>
                        <linearGradient id={`colorGradient-${hotel.id}`} x1="0" y1="0" x2="0" y2="1">
                          <stop offset="5%" stopColor={getHotelColor(hotel.id)} stopOpacity={0.8}/>
                          <stop offset="95%" stopColor={getHotelColor(hotel.id)} stopOpacity={0.1}/>
                        </linearGradient>
                      </defs>
                      <CartesianGrid strokeDasharray="3 3" vertical={false} />
                      <XAxis dataKey="name" axisLine={false} tickLine={false} />
                      <YAxis hide />
                      <Tooltip />
                      <Area type="monotone" dataKey="value" stroke={getHotelColor(hotel.id)} fillOpacity={1} fill={`url(#colorGradient-${hotel.id})`} />
                    </AreaChart>
                  </ResponsiveContainer>
                </Box>
                <Button 
                  variant="contained" 
                  fullWidth 
                  sx={{ 
                    bgcolor: getHotelColor(hotel.id),
                    '&:hover': {
                      bgcolor: getHotelColor(hotel.id),
                      opacity: 0.9,
                    }
                  }}
                  onClick={() => handleBulkAccept(hotel.id)}
                >
                  Accepteer Alle Diensten
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      {hotels.length > 3 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Button
            variant="outlined"
            onClick={() => setExpanded(!expanded)}
            endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            {expanded ? 'Toon minder' : 'Toon meer'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default HotelsOverzicht;
