import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, TextField, Button, Tabs, Tab, Snackbar, Alert } from '@mui/material';
import { collection, query, getDocs, where, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { AppContext } from '../../contexts/AppContext';
import AccountList from './AccountList';
import AccountDialog from './AccountDialog';
import AccountFilters from './AccountFilters';

const AccountManager = () => {
  const { user, setUser } = useContext(AppContext);
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingAccount, setEditingAccount] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [viewMode, setViewMode] = useState('card');
  const [hotels, setHotels] = useState([]);

  useEffect(() => {
    fetchAccounts();
    fetchHotels();
  }, []);

  const fetchAccounts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "users"));
      const accountsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAccounts(accountsData);
      setFilteredAccounts(accountsData);
    } catch (error) {
      console.error("Error fetching accounts:", error);
      setSnackbar({ open: true, message: 'Error fetching accounts', severity: 'error' });
    }
  };

  const fetchHotels = async () => {
    try {
      const querySnapshot = await getDocs(query(collection(db, "users"), where("role", "==", "hotel")));
      const hotelsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setHotels(hotelsData);
    } catch (error) {
      console.error("Error fetching hotels:", error);
    }
  };

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = accounts.filter(account => 
      account.email.toLowerCase().includes(term) ||
      account.role.toLowerCase().includes(term) ||
      (account.name && account.name.toLowerCase().includes(term))
    );
    setFilteredAccounts(filtered);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      setFilteredAccounts(accounts);
    } else {
      const roles = ['admin', 'hotel', 'user'];
      setFilteredAccounts(accounts.filter(account => account.role === roles[newValue - 1]));
    }
  };

  const handleDeleteAccount = async (accountId) => {
    try {
      await deleteDoc(doc(db, "users", accountId));
      fetchAccounts();
      setSnackbar({ open: true, message: 'Account succesvol verwijderd', severity: 'success' });
    } catch (error) {
      console.error("Fout bij het verwijderen van account:", error);
      setSnackbar({ open: true, message: 'Fout bij het verwijderen van account', severity: 'error' });
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>Account Manager</Typography>
      
      <AccountFilters 
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        setOpenDialog={setOpenDialog}
        tabValue={tabValue}
        handleTabChange={handleTabChange}
        viewMode={viewMode}
        setViewMode={setViewMode}
      />

      <AccountList 
        filteredAccounts={filteredAccounts}
        viewMode={viewMode}
        setEditingAccount={setEditingAccount}
        setOpenDialog={setOpenDialog}
        fetchAccounts={fetchAccounts}
        setSnackbar={setSnackbar}
        handleDeleteAccount={handleDeleteAccount}
      />

      <AccountDialog 
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        editingAccount={editingAccount}
        setEditingAccount={setEditingAccount}
        fetchAccounts={fetchAccounts}
        setSnackbar={setSnackbar}
        hotels={hotels}
        user={user}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({...snackbar, open: false})}
      >
        <Alert onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AccountManager;
