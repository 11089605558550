import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, useMediaQuery, useTheme, CircularProgress, Avatar, Typography, IconButton, Badge, Paper } from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { AppContext } from '../contexts/AppContext';
import { ShiftContext } from '../contexts/ShiftContext';
import { fetchShiftNotes, fetchNotifications } from '../utils/dataFetchers';
import QuickLinks from '../components/dashboard/QuickLinks';
import PendingShifts from '../components/dashboard/PendingShifts';
import UpcomingShifts from '../components/dashboard/UpcomingShifts';
import StatCards from '../components/dashboard/StatCards';
import RecentActivity from '../components/dashboard/RecentActivity';
import ShiftNotes from '../components/dashboard/ShiftNotes';
import NotificationsModal from '../components/dashboard/NotificationsModal';
import PendingShiftsPopup from '../components/PendingShiftsPopup';
import ShiftDiagram from '../components/dashboard/ShiftDiagram';
import CostDiagram from '../components/dashboard/CostDiagram';
import { ResponsiveContainer, AreaChart, Area } from 'recharts';
import { motion } from 'framer-motion';
import { TrendingUp, CheckCircle, HourglassEmpty, PendingActions } from '@mui/icons-material';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import Drawer from '@mui/material/Drawer';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import QuickShiftRequest from '../components/dashboard/QuickShiftRequest';
import NotesDrawer from '../components/dashboard/NotesDrawer';
import RosterDrawer from '../components/dashboard/RosterDrawer';
import AgendaDrawer from '../components/dashboard/AgendaDrawer';

const HotelDashboard = () => {
  const { user } = useContext(AppContext);
  const { shifts } = useContext(ShiftContext);
  const [dashboardData, setDashboardData] = useState({
    stats: {
      pendingShifts: 0,
      remainingShifts: 0,
    },
    shiftNotes: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [notificationsModalOpen, setNotificationsModalOpen] = useState(false);
  const [isPendingShiftsPopupOpen, setIsPendingShiftsPopupOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState({
    quickShift: false,
    notes: false,
    roster: false,
    agenda: false
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const fetchDashboardData = async () => {
      if (user && user.hotelId) {
        const shiftsRef = collection(db, 'diensten');
        const pendingQuery = query(
          shiftsRef,
          where('hotelId', '==', user.hotelId),
          where('status', '==', 'pending')
        );
        const approvedQuery = query(
          shiftsRef,
          where('hotelId', '==', user.hotelId),
          where('status', '==', 'approved'),
          where('start', '>', new Date())
        );

        const [pendingSnapshot, approvedSnapshot] = await Promise.all([
          getDocs(pendingQuery),
          getDocs(approvedQuery)
        ]);

        setDashboardData(prevData => ({
          ...prevData,
          stats: {
            pendingShifts: pendingSnapshot.size,
            remainingShifts: approvedSnapshot.size,
          }
        }));
      }
    };

    fetchDashboardData();
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.hotelId) {
        setIsLoading(true);
        try {
          const [notes, notifs] = await Promise.all([
            fetchShiftNotes(user.hotelId),
            fetchNotifications(user.hotelId)
          ]);
          setDashboardData(prevData => ({
            ...prevData,
            shiftNotes: notes || [],
          }));
          setNotifications(notifs || []);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [user]);

  const handleNotificationClick = () => {
    setNotificationsModalOpen(true);
  };

  const toggleDrawer = (drawer) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen({ ...drawerOpen, [drawer]: !drawerOpen[drawer] });
  };

  const MobileDashboard = () => {
    const { user } = useContext(AppContext);

    const getGreeting = () => {
      const hour = new Date().getHours();
      if (hour < 12) return `Goedemorgen`;
      if (hour < 18) return `Goedemiddag`;
      return `Goedenavond`;
    };

    return (
      <Box sx={{ flexGrow: 1, p: 1, pt: '10px', position: 'relative' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <IconButton onClick={handleNotificationClick}>
            <Badge badgeContent={notifications.length} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
          <Avatar
            src={user?.profilePictureUrl}
            alt={user?.name}
            sx={{
              width: '25vw',
              height: '25vw',
              maxWidth: 120,
              maxHeight: 120,
              mb: 2,
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)'
            }}
          />
          <Typography 
            variant="h5" 
            align="center" 
            sx={{ 
              fontWeight: 500,
              fontSize: '1.4rem',
              lineHeight: 1.3
            }}
          >
            {getGreeting()},<br />
            {user?.hotelName || 'Hotel Supernova'}
          </Typography>
        </Box>
        
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <QuickLinks
              onQuickShiftClick={toggleDrawer('quickShift')}
              onNotesClick={toggleDrawer('notes')}
              onRosterClick={toggleDrawer('roster')}
              onAgendaClick={toggleDrawer('agenda')}
            />
          </Grid>
          <Grid item xs={12}>
            <PendingShifts
              pendingShifts={dashboardData.stats.pendingShifts}
              onViewAll={() => setIsPendingShiftsPopupOpen(true)}
            />
          </Grid>
          <Grid item xs={12}>
            <UpcomingShifts shifts={shifts.filter(shift => shift.status === 'approved').slice(0, 3)} />
          </Grid>
          <Grid item xs={12}>
            <RecentActivity activities={shifts.slice(-5).reverse()} />
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <Typography variant="h6" gutterBottom>Dienstnotities</Typography>
              <ShiftNotes notes={dashboardData.shiftNotes} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <Typography variant="h6" gutterBottom>Statistieken</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body2">Openstaande Aanvragen</Typography>
                  <Typography variant="h5">{dashboardData.stats.pendingShifts}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">Resterende Diensten</Typography>
                  <Typography variant="h5">{dashboardData.stats.remainingShifts}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <Typography variant="h6" gutterBottom>Diensten Overzicht</Typography>
              <ShiftDiagram />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <Typography variant="h6" gutterBottom>Kosten Overzicht</Typography>
              <CostDiagram />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const StatCard = ({ title, value, icon, gradient, chartData }) => (
    <Paper 
      elevation={3} 
      sx={{ 
        p: 2, 
        background: gradient,
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">{title}</Typography>
        {icon}
      </Box>
      <Typography variant="h4" sx={{ mb: 2 }}>{value}</Typography>
      <Box sx={{ flexGrow: 1 }}>
        <ResponsiveContainer width="100%" height={60}>
          <AreaChart data={chartData}>
            <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" fillOpacity={0.3} />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );

  const DesktopDashboard = () => {
    const [monthlyStats, setMonthlyStats] = useState({
      totalShifts: 0,
      approvedShifts: 0,
      remainingShifts: 0,
      pendingShifts: 0
    });
    const [monthlyChartData, setMonthlyChartData] = useState({
      totalShifts: [],
      approvedShifts: [],
      remainingShifts: [],
      pendingShifts: []
    });

    useEffect(() => {
      const fetchYearlyStats = async () => {
        const currentDate = new Date();
        const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
        const endOfYear = new Date(currentDate.getFullYear(), 11, 31);

        const q = query(
          collection(db, 'diensten'),
          where('hotelId', '==', user.hotelId),
          where('start', '>=', startOfYear),
          where('start', '<=', endOfYear)
        );

        const querySnapshot = await getDocs(q);
        const shifts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const monthlyStats = Array(12).fill(0).map(() => ({
          totalShifts: 0,
          approvedShifts: 0,
          remainingShifts: 0,
          pendingShifts: 0
        }));

        shifts.forEach(shift => {
          const monthIndex = new Date(shift.start.toDate()).getMonth();
          monthlyStats[monthIndex].totalShifts++;
          if (shift.status === 'approved') {
            monthlyStats[monthIndex].approvedShifts++;
            if (new Date(shift.start.toDate()) > currentDate) {
              monthlyStats[monthIndex].remainingShifts++;
            }
          } else if (shift.status === 'pending') {
            monthlyStats[monthIndex].pendingShifts++;
          }
        });

        const totalStats = monthlyStats.reduce((acc, month) => ({
          totalShifts: acc.totalShifts + month.totalShifts,
          approvedShifts: acc.approvedShifts + month.approvedShifts,
          remainingShifts: acc.remainingShifts + month.remainingShifts,
          pendingShifts: acc.pendingShifts + month.pendingShifts
        }), { totalShifts: 0, approvedShifts: 0, remainingShifts: 0, pendingShifts: 0 });

        setMonthlyStats(totalStats);

        setMonthlyChartData({
          totalShifts: monthlyStats.map((month, index) => ({ month: index + 1, value: month.totalShifts })),
          approvedShifts: monthlyStats.map((month, index) => ({ month: index + 1, value: month.approvedShifts })),
          remainingShifts: monthlyStats.map((month, index) => ({ month: index + 1, value: month.remainingShifts })),
          pendingShifts: monthlyStats.map((month, index) => ({ month: index + 1, value: month.pendingShifts }))
        });
      };

      fetchYearlyStats();
    }, [user.hotelId]);

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, borderBottom: 1, borderColor: 'divider' }}>
          <Typography variant="h4">Dashboard</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={handleNotificationClick} sx={{ mr: 2 }}>
              <Badge badgeContent={notifications.length} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Avatar src={user?.profilePictureUrl} alt={user?.name} />
            <Typography variant="subtitle1" sx={{ ml: 1 }}>{user?.name || 'Gebruiker'}</Typography>
          </Box>
        </Box>
        
        <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
          <Box sx={{ width: '70%', p: 2, overflowY: 'auto' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom>Statistieken</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <StatCard
                        title="Totale Diensten"
                        value={monthlyStats.totalShifts}
                        icon={<TrendingUp />}
                        gradient="linear-gradient(45deg, #FFE0B2 30%, #FFCC80 90%)"
                        chartData={monthlyChartData.totalShifts}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <StatCard
                        title="Goedgekeurde Diensten"
                        value={monthlyStats.approvedShifts}
                        icon={<CheckCircle />}
                        gradient="linear-gradient(45deg, #C8E6C9 30%, #A5D6A7 90%)"
                        chartData={monthlyChartData.approvedShifts}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <StatCard
                        title="Resterende Diensten"
                        value={monthlyStats.remainingShifts}
                        icon={<HourglassEmpty />}
                        gradient="linear-gradient(45deg, #B3E5FC 30%, #81D4FA 90%)"
                        chartData={monthlyChartData.remainingShifts}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <StatCard
                        title="Openstaande Aanvragen"
                        value={monthlyStats.pendingShifts}
                        icon={<PendingActions />}
                        gradient="linear-gradient(45deg, #FFCDD2 30%, #EF9A9A 90%)"
                        chartData={monthlyChartData.pendingShifts}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <RecentActivity activities={shifts.slice(-10).reverse()} />
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom>Diensten Overzicht</Typography>
                  <ShiftDiagram />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom>Kosten Overzicht</Typography>
                  <CostDiagram />
                </Paper>
              </Grid>
            </Grid>
          </Box>
          
          <Box sx={{ width: '30%', p: 2, overflowY: 'auto', borderLeft: 1, borderColor: 'divider' }}>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <Typography variant="h6" gutterBottom>Snelle Acties</Typography>
              <QuickLinks
                onQuickShiftClick={toggleDrawer('quickShift')}
                onNotesClick={toggleDrawer('notes')}
                onRosterClick={toggleDrawer('roster')}
                onAgendaClick={toggleDrawer('agenda')}
              />
            </Paper>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <PendingShifts
                pendingShifts={dashboardData.stats.pendingShifts}
                onViewAll={() => setIsPendingShiftsPopupOpen(true)}
              />
            </Paper>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <UpcomingShifts shifts={shifts.filter(shift => shift.status === 'approved').slice(0, 5)} />
            </Paper>
            <Paper elevation={3} sx={{ p: 2 }}>
              <ShiftNotes notes={dashboardData.shiftNotes} />
            </Paper>
          </Box>
        </Box>

        {/* Drawers */}
        <Drawer
          anchor="right"
          open={drawerOpen.quickShift}
          onClose={toggleDrawer('quickShift')}
        >
          <Box sx={{ width: 350, p: 2 }}>
            <IconButton onClick={toggleDrawer('quickShift')} sx={{ mb: 2 }}>
              <ChevronRightIcon />
            </IconButton>
            <QuickShiftRequest />
          </Box>
        </Drawer>

        <Drawer
          anchor="right"
          open={drawerOpen.notes}
          onClose={toggleDrawer('notes')}
        >
          <Box sx={{ width: 350, p: 2 }}>
            <IconButton onClick={toggleDrawer('notes')} sx={{ mb: 2 }}>
              <ChevronRightIcon />
            </IconButton>
            <NotesDrawer />
          </Box>
        </Drawer>

        <Drawer
          anchor="right"
          open={drawerOpen.roster}
          onClose={toggleDrawer('roster')}
        >
          <Box sx={{ width: 350, p: 2 }}>
            <IconButton onClick={toggleDrawer('roster')} sx={{ mb: 2 }}>
              <ChevronRightIcon />
            </IconButton>
            <RosterDrawer />
          </Box>
        </Drawer>

        <Drawer
          anchor="right"
          open={drawerOpen.agenda}
          onClose={toggleDrawer('agenda')}
        >
          <Box sx={{ width: 350, p: 2 }}>
            <IconButton onClick={toggleDrawer('agenda')} sx={{ mb: 2 }}>
              <ChevronRightIcon />
            </IconButton>
            <AgendaDrawer />
          </Box>
        </Drawer>
      </Box>
    );
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {isMobile ? (
        <MobileDashboard />
      ) : (
        <DesktopDashboard />
      )}

      <NotificationsModal
        open={notificationsModalOpen}
        onClose={() => setNotificationsModalOpen(false)}
        notifications={notifications}
      />

      <PendingShiftsPopup
        open={isPendingShiftsPopupOpen}
        onClose={() => setIsPendingShiftsPopupOpen(false)}
      />
    </>
  );
};

export default HotelDashboard;