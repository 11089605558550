import React, { createContext, useState, useEffect, useContext } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { AppContext } from './AppContext';

export const ShiftContext = createContext();

export const ShiftProvider = ({ children }) => {
  const [shifts, setShifts] = useState([]);
  const { user } = useContext(AppContext);

  useEffect(() => {
    if (user && user.hotelId) {
      const shiftsRef = collection(db, 'diensten');
      const q = query(shiftsRef, where('hotelId', '==', user.hotelId));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const shiftsData = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            start: data.start?.toDate?.() || new Date(data.start),
            end: data.end?.toDate?.() || new Date(data.end),
          };
        });
        setShifts(shiftsData);
      });

      return () => unsubscribe();
    }
  }, [user]);

  return (
    <ShiftContext.Provider value={{ shifts, setShifts }}>
      {children}
    </ShiftContext.Provider>
  );
};
