import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Divider, CircularProgress, Tabs, Tab, TextField, Button } from '@mui/material';
import { collection, query, where, orderBy, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { AppContext } from '../../contexts/AppContext';
import moment from 'moment';

const NotesDrawer = () => {
  const [upcomingShifts, setUpcomingShifts] = useState([]);
  const [pastShifts, setPastShifts] = useState([]);
  const [pendingShifts, setPendingShifts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [newNote, setNewNote] = useState('');
  const [selectedShift, setSelectedShift] = useState(null);
  const { user } = useContext(AppContext);

  useEffect(() => {
    if (user && user.hotelId) {
      fetchShifts();
    }
  }, [user]);

  const fetchShifts = async () => {
    setLoading(true);
    const now = new Date();
    const shiftsRef = collection(db, 'diensten');

    // Fetch upcoming shifts
    const upcomingQuery = query(
      shiftsRef,
      where('hotelId', '==', user.hotelId),
      where('start', '>=', now),
      orderBy('start', 'asc')
    );
    const upcomingSnapshot = await getDocs(upcomingQuery);
    const upcomingData = upcomingSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setUpcomingShifts(upcomingData);

    // Fetch past shifts
    const pastQuery = query(
      shiftsRef,
      where('hotelId', '==', user.hotelId),
      where('end', '<', now),
      orderBy('end', 'desc')
    );
    const pastSnapshot = await getDocs(pastQuery);
    const pastData = pastSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setPastShifts(pastData);

    // Fetch pending shifts
    const pendingQuery = query(
      shiftsRef,
      where('hotelId', '==', user.hotelId),
      where('status', '==', 'pending'),
      orderBy('start', 'asc')
    );
    const pendingSnapshot = await getDocs(pendingQuery);
    const pendingData = pendingSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setPendingShifts(pendingData);

    setLoading(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleAddNote = async () => {
    if (selectedShift && newNote.trim()) {
      try {
        const shiftRef = doc(db, 'diensten', selectedShift.id);
        await updateDoc(shiftRef, {
          notes: newNote
        });
        // Update local state
        setPendingShifts(prevShifts =>
          prevShifts.map(shift =>
            shift.id === selectedShift.id ? { ...shift, notes: newNote } : shift
          )
        );
        setNewNote('');
        setSelectedShift(null);
      } catch (error) {
        console.error("Error adding note:", error);
      }
    }
  };

  const renderShiftNote = (shift) => (
    <ListItem key={shift.id}>
      <ListItemText
        primary={`${moment(shift.start.toDate()).format('DD/MM/YYYY HH:mm')} - ${shift.type}`}
        secondary={shift.notes || 'Geen notities'}
      />
      {activeTab === 0 && (
        <Button onClick={() => setSelectedShift(shift)}>
          {shift.notes ? 'Bewerk notitie' : 'Voeg notitie toe'}
        </Button>
      )}
    </ListItem>
  );

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <Typography variant="h6" sx={{ p: 2 }}>Notities</Typography>
      
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Pending" />
        <Tab label="Aankomend" />
        <Tab label="Verstreken" />
      </Tabs>

      {activeTab === 0 && (
        <>
          <List>
            {pendingShifts.length > 0 ? (
              pendingShifts.map(renderShiftNote)
            ) : (
              <ListItem>
                <ListItemText primary="Geen pending diensten met notities" />
              </ListItem>
            )}
          </List>
          {selectedShift && (
            <Box sx={{ p: 2 }}>
              <TextField
                fullWidth
                multiline
                rows={3}
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                placeholder="Voeg een notitie toe..."
              />
              <Button onClick={handleAddNote} sx={{ mt: 1 }}>
                Opslaan
              </Button>
            </Box>
          )}
        </>
      )}

      {activeTab === 1 && (
        <List>
          {upcomingShifts.length > 0 ? (
            upcomingShifts.map(renderShiftNote)
          ) : (
            <ListItem>
              <ListItemText primary="Geen aankomende diensten met notities" />
            </ListItem>
          )}
        </List>
      )}

      {activeTab === 2 && (
        <List>
          {pastShifts.length > 0 ? (
            pastShifts.map(renderShiftNote)
          ) : (
            <ListItem>
              <ListItemText primary="Geen verstreken diensten met notities" />
            </ListItem>
          )}
        </List>
      )}
    </Box>
  );
};

export default NotesDrawer;