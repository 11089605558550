import React from 'react';
import { Typography, List, ListItem, ListItemText, Box, useTheme, Chip } from '@mui/material';
import { Event, CheckCircle, Cancel, HourglassEmpty } from '@mui/icons-material';
import moment from 'moment';

const statusColors = {
  pending: '#FFD54F',  // Zachter geel
  approved: '#81C784',  // Zachter groen
  rejected: '#E57373',  // Zachter rood
};

const statusIcons = {
  pending: <HourglassEmpty fontSize="small" />,
  approved: <CheckCircle fontSize="small" />,
  rejected: <Cancel fontSize="small" />,
};

const RecentActivity = ({ activities }) => {
  const theme = useTheme();

  return (
    <>
      <Typography variant="h6" gutterBottom>Recent Activity</Typography>
      <List sx={{ overflow: 'auto', maxHeight: 400 }}>
        {activities.map((activity, index) => (
          <ListItem 
            key={index}
            sx={{ 
              mb: 1, 
              borderRadius: 1,
              backgroundColor: theme.palette.background.paper,
              border: `1px solid ${theme.palette.divider}`,
            }}
          >
            <ListItemText 
              primary={
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" alignItems="center">
                    <Event fontSize="small" color="action" sx={{ mr: 1 }} />
                    <Typography variant="subtitle2">{`${activity.type} Shift`}</Typography>
                  </Box>
                  <Chip
                    icon={statusIcons[activity.status]}
                    label={activity.status.charAt(0).toUpperCase() + activity.status.slice(1)}
                    size="small"
                    sx={{
                      backgroundColor: `${statusColors[activity.status]}30`,
                      color: statusColors[activity.status],
                      fontWeight: 'medium',
                      '& .MuiChip-icon': {
                        color: statusColors[activity.status],
                      },
                    }}
                  />
                </Box>
              }
              secondary={
                <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                  {`${moment(activity.start).format('MMM D, YYYY HH:mm')} - ${moment(activity.end).format('HH:mm')}`}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default RecentActivity;
