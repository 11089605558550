import React, { useState, useContext, useEffect } from 'react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../contexts/AppContext";
import { TextField, Button, Typography, Container, Box, Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [newUserRole, setNewUserRole] = useState("user");
  const navigate = useNavigate();
  const { setUser, user } = useContext(AppContext);

  useEffect(() => {
    console.log("Current user:", user);
  }, [user]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userDoc = await getDoc(doc(db, "users", userCredential.user.uid));
      const userData = userDoc.data();
      console.log("User data from Firestore:", userData);
      if (userData) {
        setUser({ ...userCredential.user, ...userData });
        navigate(userData.role === "admin" ? "/admin" : userData.role === "hotel" ? "/hotel" : "/user");
      } else {
        setError("User data not found in Firestore");
      }
    } catch (error) {
      console.error("Login error:", error);
      setError(error.message);
    }
  };

  const handleCreateUser = async (e) => {
    e.preventDefault();
    if (!user) {
      console.log("No user logged in");
      setError("You must be logged in to create a new user");
      return;
    }
    if (user.role !== 'hotel' && user.role !== 'admin') {
      console.log("User role:", user.role);
      setError("Only hotels and admins can create new users");
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, newUserEmail, newUserPassword);
      await setDoc(doc(db, "users", userCredential.user.uid), {
        email: newUserEmail,
        role: newUserRole,
        hotelId: user.role === 'hotel' ? user.hotelId : null
      });
      console.log("New user created successfully");
      setOpenDialog(false);
    } catch (error) {
      console.error("Error creating new user:", error);
      setError(error.message);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="login tabs">
          <Tab label="Login" />
          <Tab label="Create User" />
        </Tabs>
        {tabValue === 0 && (
          <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        )}
        {tabValue === 1 && (
          <Box sx={{ mt: 1 }}>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => setOpenDialog(true)}
              disabled={!user || (user.role !== 'hotel' && user.role !== 'admin')}
            >
              Create New User
            </Button>
          </Box>
        )}
        {error && (
          <Typography color="error" align="center">
            {error}
          </Typography>
        )}
      </Box>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="newEmail"
            label="Email Address"
            type="email"
            fullWidth
            value={newUserEmail}
            onChange={(e) => setNewUserEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            id="newPassword"
            label="Password"
            type="password"
            fullWidth
            value={newUserPassword}
            onChange={(e) => setNewUserPassword(e.target.value)}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="role-select-label">Role</InputLabel>
            <Select
              labelId="role-select-label"
              id="role-select"
              value={newUserRole}
              label="Role"
              onChange={(e) => setNewUserRole(e.target.value)}
            >
              <MenuItem value="user">User</MenuItem>
              {user && user.role === 'admin' && (
                <>
                  <MenuItem value="hotel">Hotel</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </>
              )}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleCreateUser}>Create</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Login;
