import React, { createContext, useState, useEffect } from "react";
import { auth, db } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userHotel, setUserHotel] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const userDoc = await getDoc(doc(db, "users", firebaseUser.uid));
        if (userDoc.exists()) {
          const userData = { ...firebaseUser, ...userDoc.data() };
          setUser(userData);
          if (userData.hotelId) {
            const hotelDoc = await getDoc(doc(db, "hotels", userData.hotelId));
            if (hotelDoc.exists()) {
              setUserHotel(hotelDoc.data());
            }
          }
        } else {
          setUser(firebaseUser);
        }
      } else {
        setUser(null);
        setUserHotel(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AppContext.Provider value={{ user, setUser, loading, userHotel }}>
      {children}
    </AppContext.Provider>
  );
};
