import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, Typography, Box, IconButton, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import moment from 'moment';

const ShiftConfirmationModal = ({ show, shifts, onConfirm, onClose, onDeleteShift, onUpdateShift }) => {
  const [editingNote, setEditingNote] = useState(null);
  const [tempNote, setTempNote] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const calculateTotalHours = (shifts) => {
    return shifts.reduce((total, shift) => {
      const start = moment(shift.startTime, 'HH:mm');
      const end = moment(shift.endTime, 'HH:mm');
      const duration = moment.duration(end.diff(start));
      return total + duration.asHours();
    }, 0).toFixed(2);
  };

  const handleEditNote = (index, currentNote) => {
    setEditingNote(index);
    setTempNote(currentNote || '');
  };

  const handleSaveNote = (index) => {
    onUpdateShift(index, { ...shifts[index], notes: tempNote });
    setEditingNote(null);
    setTempNote('');
  };

  const handleNoteChange = (e) => {
    setTempNote(e.target.value);
  };

  return (
    <Dialog open={show} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Bevestig Diensten</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom sx={{ marginBottom: 2, paddingTop: 3 }}>
          Wilt u deze diensten aanvragen?
        </Typography>
        <List>
          {shifts.map((shift, index) => (
            <ListItem key={index} sx={{ backgroundColor: '#e0e0e0', marginBottom: 1, borderRadius: 1, '&:hover': { backgroundColor: '#d0d0d0' } }}>
              <ListItemText
                primary={`${shift.type} op ${moment(shift.date).format('DD/MM/YYYY')}`}
                secondary={`Van ${shift.startTime} tot ${shift.endTime}`}
              />
              <Box>
                {editingNote === index ? (
                  <>
                    <TextField
                      value={tempNote}
                      onChange={handleNoteChange}
                      autoFocus
                      fullWidth
                      multiline
                      variant="outlined"
                      size="small"
                    />
                    <Button onClick={() => handleSaveNote(index)}>Save</Button>
                  </>
                ) : (
                  <>
                    <Tooltip title={shift.notes || 'Voeg notitie toe'}>
                      <IconButton onClick={() => handleEditNote(index, shift.notes)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <IconButton onClick={() => onDeleteShift(index)} style={{ color: '#5c5c5c' }}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </ListItem>
          ))}
        </List>
        <Box mt={2}>
          <Typography variant="body2">
            Totaal aantal diensten: {shifts.length}
          </Typography>
          <Typography variant="body2">
            Totaal aantal uren: {calculateTotalHours(shifts)} uur
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2, backgroundColor: '#f5f5f5', flexDirection: isMobile ? 'column' : 'row' }}>
        <Button onClick={onClose} variant="outlined" fullWidth={isMobile} sx={{ mb: isMobile ? 1 : 0 }}>
          Annuleren
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained" fullWidth={isMobile}>
          Bevestigen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShiftConfirmationModal;
