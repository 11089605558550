import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const MultiSelectShiftPopup = ({ open, onClose, shift, onUpdate, onDelete }) => {
  const [editedShift, setEditedShift] = useState(null);

  useEffect(() => {
    if (shift) {
      setEditedShift(shift);
    }
  }, [shift]);

  const handleChange = (e) => {
    setEditedShift({ ...editedShift, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    if (editedShift) {
      await onUpdate(editedShift);
    }
    onClose();
  };

  const handleDelete = async () => {
    if (editedShift) {
      await onDelete(editedShift.id);
    }
    onClose();
  };

  if (!editedShift) return null;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Shift</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>Shift Type</InputLabel>
          <Select
            name="type"
            value={editedShift.type}
            onChange={handleChange}
          >
            <MenuItem value="Night Receptionist">Night Receptionist</MenuItem>
            <MenuItem value="Night Porter">Night Porter</MenuItem>
            <MenuItem value="Night Manager">Night Manager</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          margin="normal"
          label="Start Time"
          type="time"
          name="startTime"
          value={editedShift.startTime}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="End Time"
          type="time"
          name="endTime"
          value={editedShift.endTime}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Notes"
          multiline
          rows={4}
          name="notes"
          value={editedShift.notes}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleDelete} color="error">Delete</Button>
        <Button onClick={handleSave} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultiSelectShiftPopup;
