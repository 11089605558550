import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Paper, List, ListItem, ListItemText, Chip, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, useMediaQuery, useTheme, Avatar, styled } from '@mui/material';
import { collection, addDoc, getDocs, query, orderBy, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import '../styles/PersonnelManager.css';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 60,
  height: 60,
  marginBottom: theme.spacing(2),
  // Verwijder de border eigenschap
}));

const PersonnelManager = () => {
  const [personnel, setPersonnel] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingPerson, setEditingPerson] = useState(null);
  const [newPerson, setNewPerson] = useState({
    name: '',
    email: '',
    phone: '',
    position: '',
    associatedHotels: [],
  });
  const [selectedFile, setSelectedFile] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchPersonnel();
  }, []);

  const fetchPersonnel = async () => {
    try {
      const personnelQuery = query(collection(db, 'personnel'), orderBy('createdAt', 'desc'));
      const personnelSnapshot = await getDocs(personnelQuery);
      const personnelData = personnelSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPersonnel(personnelData);
    } catch (error) {
      console.error('Error fetching personnel:', error);
    }
  };

  const handleOpenDialog = (person = null) => {
    if (person) {
      setEditingPerson(person);
      setNewPerson(person);
    } else {
      setEditingPerson(null);
      setNewPerson({
        name: '',
        email: '',
        phone: '',
        position: '',
        associatedHotels: [],
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingPerson(null);
    setNewPerson({
      name: '',
      email: '',
      phone: '',
      position: '',
      associatedHotels: [],
    });
    setSelectedFile(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPerson(prev => ({ ...prev, [name]: value }));
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    try {
      let photoURL = newPerson.photoURL;

      if (selectedFile) {
        const storageRef = ref(storage, `personnel-photos/${editingPerson ? editingPerson.id : Date.now()}`);
        await uploadBytes(storageRef, selectedFile);
        photoURL = await getDownloadURL(storageRef);
      }

      if (editingPerson) {
        await updateDoc(doc(db, 'personnel', editingPerson.id), {
          ...newPerson,
          photoURL,
          updatedAt: new Date()
        });
      } else {
        await addDoc(collection(db, 'personnel'), {
          ...newPerson,
          photoURL,
          createdAt: new Date()
        });
      }
      handleCloseDialog();
      fetchPersonnel();
    } catch (error) {
      console.error('Error adding/updating personnel:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this person?')) {
      try {
        await deleteDoc(doc(db, 'personnel', id));
        fetchPersonnel();
      } catch (error) {
        console.error('Error deleting personnel:', error);
      }
    }
  };

  return (
    <Box className="personnel-manager-container" sx={{ flexGrow: 1, p: isMobile ? 2 : 3 }}>
      <Typography variant="h4" gutterBottom>
        Personnel Manager
      </Typography>
      <Button variant="contained" onClick={() => handleOpenDialog()} sx={{ mb: 3 }}>
        Add New Personnel
      </Button>
      <Grid container spacing={isMobile ? 2 : 3}>
        {personnel.map((person) => (
          <Grid item xs={12} sm={6} md={4} key={person.id}>
            <StyledPaper>
              <StyledAvatar src={person.photoURL} alt={person.name} />
              <Typography variant="h6">{person.name}</Typography>
              <Typography variant="body2">Email: {person.email}</Typography>
              <Typography variant="body2">Phone: {person.phone}</Typography>
              <Typography variant="body2">Position: {person.position}</Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>Associated Hotels:</Typography>
              <Box sx={{ mt: 1, mb: 1 }}>
                {person.associatedHotels && person.associatedHotels.length > 0 ? (
                  person.associatedHotels.map((hotel, index) => (
                    <Chip key={index} label={hotel} size="small" sx={{ mr: 0.5, mb: 0.5 }} />
                  ))
                ) : (
                  <Typography variant="body2">None</Typography>
                )}
              </Box>
              <Box sx={{ mt: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={() => handleOpenDialog(person)} size="small" sx={{ color: 'primary.main', '&:hover': { backgroundColor: 'primary.light' } }}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDelete(person.id)} size="small" sx={{ color: 'primary.main', '&:hover': { backgroundColor: 'primary.light' } }}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </StyledPaper>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog} fullScreen={isMobile}>
        <DialogTitle>{editingPerson ? 'Edit Personnel' : 'Add New Personnel'}</DialogTitle>
        <DialogContent sx={{ backgroundColor: 'background.default' }}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={newPerson.name}
            onChange={handleInputChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={newPerson.email}
            onChange={handleInputChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            value={newPerson.phone}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Position"
            name="position"
            value={newPerson.position}
            onChange={handleInputChange}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Associated Hotels</InputLabel>
            <Select
              multiple
              name="associatedHotels"
              value={newPerson.associatedHotels}
              onChange={handleInputChange}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {/* Add MenuItem for each hotel */}
              <MenuItem value="Hotel A">Hotel A</MenuItem>
              <MenuItem value="Hotel B">Hotel B</MenuItem>
              <MenuItem value="Hotel C">Hotel C</MenuItem>
            </Select>
          </FormControl>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={handleFileSelect}
          />
          <label htmlFor="raised-button-file">
            <Button variant="contained" component="span" startIcon={<PhotoCamera />} sx={{ mt: 2 }}>
              Upload Profile Picture
            </Button>
          </label>
          {selectedFile && <Typography variant="body2" sx={{ mt: 1 }}>{selectedFile.name}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            }}
          >
            {editingPerson ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PersonnelManager;
