import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Grid, Paper, Button, Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText, Collapse, IconButton } from '@mui/material';
import { AppContext } from '../contexts/AppContext';
import { collection, query, where, getDocs, addDoc, Timestamp } from "firebase/firestore";
import { db } from '../firebase';
import moment from 'moment';
import 'moment/locale/nl';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

moment.locale('nl');
const localizer = momentLocalizer(moment);

const AdminCalendar = () => {
  const { user } = useContext(AppContext);

  const [hotels, setHotels] = useState([]);
  const [selectedHotels, setSelectedHotels] = useState([]);
  const [newEvents, setNewEvents] = useState([]);
  const [personnel, setPersonnel] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [shiftTypes] = useState(['Nachtdienst', 'Dagdienst', 'Avonddienst']);
  const [selectedShiftTypes, setSelectedShiftTypes] = useState([]);
  const [showInstructions, setShowInstructions] = useState(true);

  useEffect(() => {
    fetchHotels();
    fetchPersonnel();
    fetchShifts();
  }, []);

  const fetchHotels = async () => {
    try {
      const hotelsQuery = query(collection(db, "users"), where("role", "==", "hotel"));
      const hotelsSnapshot = await getDocs(hotelsQuery);
      const hotelsList = hotelsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setHotels(hotelsList);
      console.log('Hotels opgehaald:', hotelsList);
    } catch (error) {
      console.error("Fout bij ophalen hotels:", error);
    }
  };

  const fetchPersonnel = async () => {
    try {
      const personnelQuery = query(collection(db, "Personnel"));
      const personnelSnapshot = await getDocs(personnelQuery);
      const personnelList = personnelSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPersonnel(personnelList);
      console.log('Personeel opgehaald:', personnelList);
    } catch (error) {
      console.error("Fout bij ophalen personeel:", error);
    }
  };

  const fetchShifts = async () => {
    try {
      const shiftsQuery = query(collection(db, "diensten"));
      const shiftsSnapshot = await getDocs(shiftsQuery);
      const shiftsList = shiftsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setShifts(shiftsList);
      console.log('Diensten opgehaald:', shiftsList);
    } catch (error) {
      console.error("Fout bij ophalen diensten:", error);
    }
  };

  const handleSelectSlot = ({ start }) => {
    const newEvent = {
      date: start,
      type: 'Nachtdienst',
      startTime: '22:45',
      endTime: '06:45',
      count: 1,
      notes: '',
      tempId: start.getTime(),
      isNew: true, // Markeer nieuwe events
    };
    setNewEvents(prev => [...prev, newEvent]);
    console.log('Nieuwe dienst toegevoegd:', newEvent);
  };

  const handleCreateEvents = async () => {
    if (selectedHotels.length > 0 && newEvents.length > 0) {
      try {
        const createdEvents = await Promise.all(newEvents.flatMap(event => 
          selectedHotels.map(async (hotelId) => {
            const selectedHotelData = hotels.find(hotel => hotel.id === hotelId);
            const startDateTime = moment(event.date).set({
              hour: parseInt(event.startTime.split(':')[0]),
              minute: parseInt(event.startTime.split(':')[1])
            });
            const endDateTime = moment(event.date).add(1, 'day').set({
              hour: parseInt(event.endTime.split(':')[0]),
              minute: parseInt(event.endTime.split(':')[1])
            });

            const eventData = {
              type: event.type,
              start: Timestamp.fromDate(startDateTime.toDate()),
              end: Timestamp.fromDate(endDateTime.toDate()),
              userId: hotelId,
              hotelId: hotelId,
              hotelName: selectedHotelData.name,
              status: 'accepted', // Verander de status naar 'accepted'
              count: event.count,
              notes: event.notes,
              createdAt: Timestamp.now()
            };

            const docRef = await addDoc(collection(db, "diensten"), eventData);
            return { id: docRef.id, ...eventData };
          })
        ));

        console.log('Diensten aangemaakt:', createdEvents);
        setNewEvents([]);
        fetchShifts(); // Haal de diensten opnieuw op om de lijst bij te werken
        alert("Diensten zijn succesvol aangemaakt!");
      } catch (error) {
        console.error("Error creating events:", error);
        alert("Er is een fout opgetreden bij het aanmaken van de diensten. Probeer het opnieuw.");
      }
    } else {
      alert("Selecteer minstens één hotel en voeg diensten toe voordat u ze aanmaakt.");
    }
  };

  const filteredShifts = shifts.filter(shift => 
    (selectedHotels.length === 0 || selectedHotels.includes(shift.hotelId)) &&
    (selectedShiftTypes.length === 0 || selectedShiftTypes.includes(shift.type))
  );

  const calendarEvents = [
    ...filteredShifts.map(shift => ({
      ...shift,
      start: moment(shift.start.toDate()).startOf('day').toDate(),
      end: moment(shift.start.toDate()).endOf('day').toDate(),
      title: `${shift.type} - ${shift.hotelName}`,
      isNew: false,
    })),
    ...newEvents.map(e => ({
      ...e,
      start: moment(e.date).startOf('day').toDate(),
      end: moment(e.date).endOf('day').toDate(),
      title: `Nieuwe ${e.type}`,
      isNew: true,
    }))
  ];

  const eventStyleGetter = (event, start, end, isSelected) => {
    let style = {
      backgroundColor: event.isNew ? 'orange' : '#3174ad',
      borderRadius: '0px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block',
      fontSize: '0.8em', // Kleinere tekstgrootte
    };
    return {
      style: style
    };
  };

  console.log('Calendar events:', calendarEvents); // Debug logging

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Diensten Uitvragen</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Selecteer Hotels</InputLabel>
              <Select
                multiple
                value={selectedHotels}
                onChange={(e) => setSelectedHotels(e.target.value)}
                renderValue={(selected) => selected.map(id => hotels.find(h => h.id === id)?.name).join(', ')}
              >
                {hotels.map((hotel) => (
                  <MenuItem key={hotel.id} value={hotel.id}>
                    <Checkbox checked={selectedHotels.indexOf(hotel.id) > -1} />
                    <ListItemText primary={hotel.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Diensttype Filter</InputLabel>
              <Select
                multiple
                value={selectedShiftTypes}
                onChange={(e) => setSelectedShiftTypes(e.target.value)}
                renderValue={(selected) => selected.join(', ')}
              >
                {shiftTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    <Checkbox checked={selectedShiftTypes.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateEvents}
              sx={{ mt: 2 }}
              fullWidth
              disabled={selectedHotels.length === 0 || newEvents.length === 0}
            >
              Diensten Aanmaken ({newEvents.length})
            </Button>

            {/* Instructiebox */}
            <Collapse in={showInstructions}>
              <Paper sx={{ mt: 2, p: 2, bgcolor: 'info.light' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                    <InfoIcon sx={{ mr: 1 }} /> Hoe diensten uit te vragen
                  </Typography>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => setShowInstructions(false)}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </Box>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  1. Selecteer één of meerdere hotels.<br />
                  2. Klik op een datum in de kalender om een nieuwe dienst toe te voegen.<br />
                  3. Pas indien nodig het type dienst aan.<br />
                  4. Herhaal stap 2-3 voor meerdere diensten.<br />
                  5. Klik op "Diensten Aanmaken" om de geselecteerde diensten aan te maken.
                </Typography>
              </Paper>
            </Collapse>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper sx={{ p: 2, height: 'calc(100vh - 200px)' }}>
            {hotels.length > 0 ? (
              <Calendar
                localizer={localizer}
                events={calendarEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ height: '100%' }}
                onSelectSlot={handleSelectSlot}
                selectable
                views={['month', 'week', 'day']}
                defaultView='month'
                eventPropGetter={eventStyleGetter}
                messages={{
                  today: 'Vandaag',
                  previous: 'Vorige',
                  next: 'Volgende',
                  month: 'Maand',
                  week: 'Week',
                  day: 'Dag',
                }}
              />
            ) : (
              <Typography>Laden van hotels...</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminCalendar;
