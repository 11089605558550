import React, { useState, useContext, useEffect } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Box,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { ShiftContext } from '../contexts/ShiftContext';
import moment from 'moment';
import 'moment/locale/nl';

moment.locale('nl');

const PendingShiftsPopup = ({ open, onClose }) => {
  const { shifts, updateShift, deleteShift } = useContext(ShiftContext);
  const [editingShift, setEditingShift] = useState(null);
  const [editedNotes, setEditedNotes] = useState('');
  const [pendingShifts, setPendingShifts] = useState([]);

  useEffect(() => {
    const filteredShifts = shifts.filter(shift => shift.status === 'pending');
    setPendingShifts(filteredShifts);
    console.log('Pending shifts:', filteredShifts); // Debug log
  }, [shifts]);

  const handleEdit = (shift) => {
    setEditingShift(shift);
    setEditedNotes(shift.notes || '');
  };

  const handleSaveEdit = async () => {
    if (editingShift) {
      await updateShift(editingShift.id, { ...editingShift, notes: editedNotes });
      setEditingShift(null);
    }
  };

  const handleDelete = async (shiftId) => {
    if (window.confirm('Weet je zeker dat je deze dienst wilt verwijderen?')) {
      await deleteShift(shiftId);
    }
  };

  const formatDate = (dateString) => {
    return moment(dateString).format('DD MMMM YYYY');
  };

  const formatTime = (dateString) => {
    return moment(dateString).format('HH:mm');
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 400,
          bgcolor: 'background.default',
          borderLeft: '1px solid',
          borderColor: 'divider',
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2, borderBottom: '1px solid', borderColor: 'divider' }}>
        <IconButton onClick={onClose} sx={{ mr: 2 }}>
          <ChevronRight />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Openstaande Diensten
        </Typography>
      </Box>
      <Box sx={{ p: 2, overflowY: 'auto', height: 'calc(100% - 64px)' }}>
        {pendingShifts.length === 0 ? (
          <Typography>Er zijn geen openstaande diensten.</Typography>
        ) : (
          <List>
            {pendingShifts.map((shift) => (
              <ListItem
                key={shift.id}
                alignItems="flex-start"
                sx={{
                  mb: 2,
                  borderRadius: 1,
                  bgcolor: 'pendingShift.light',
                  '&:hover': {
                    bgcolor: 'pendingShift.main',
                  },
                }}
              >
                <ListItemText
                  primary={`${formatDate(shift.start)} - ${shift.type}`}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="text.primary">
                        {`${formatTime(shift.start)} - ${formatTime(shift.end)}`}
                      </Typography>
                      {shift.notes && (
                        <Typography component="p" variant="body2">
                          Notities: {shift.notes}
                        </Typography>
                      )}
                    </>
                  }
                />
                <Box>
                  <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(shift)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(shift.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </ListItem>
            ))}
          </List>
        )}
      </Box>

      {/* Edit Shift Dialog */}
      <Dialog open={!!editingShift} onClose={() => setEditingShift(null)}>
        <DialogTitle>Dienst Bewerken</DialogTitle>
        <DialogContent>
          {editingShift && (
            <>
              <Typography>{formatDate(editingShift.start)}</Typography>
              <Typography>{`${formatTime(editingShift.start)} - ${formatTime(editingShift.end)}`}</Typography>
              <TextField
                autoFocus
                margin="dense"
                id="notes"
                label="Notities"
                type="text"
                fullWidth
                variant="outlined"
                value={editedNotes}
                onChange={(e) => setEditedNotes(e.target.value)}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingShift(null)}>Annuleren</Button>
          <Button onClick={handleSaveEdit}>Opslaan</Button>
        </DialogActions>
      </Dialog>
    </Drawer>
  );
};

export default PendingShiftsPopup;
