import React from 'react';
import { Box, TextField, Button, Typography, Rating, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';

const EditHotelForm = ({ hotel, onUpdate, onCancel, onDelete }) => {
  const [editedHotel, setEditedHotel] = React.useState(hotel);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditedHotel(prev => ({ ...prev, [name]: value }));
  };

  const handleRatingChange = (event, newValue) => {
    setEditedHotel(prev => ({ ...prev, stars: newValue }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onUpdate(editedHotel);
  };

  const serviceTypes = [
    'Night Receptionist',
    'Day Receptionist',
    'Night Porter',
    'Night Manager'
  ];

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Hotel Name"
            name="name"
            value={editedHotel.name || ''}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Hotel Email"
            name="email"
            value={editedHotel.email || ''}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Hotel Address"
            name="location"
            value={editedHotel.location || ''}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Hotel Rate"
            name="rate"
            type="number"
            value={editedHotel.rate || ''}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Image URL"
            name="imageUrl"
            value={editedHotel.imageUrl || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Contact Person"
            name="contactPerson"
            value={editedHotel.contactPerson || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="legend">Stars</Typography>
          <Rating
            name="stars"
            value={editedHotel.stars || 0}
            onChange={handleRatingChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Service Types</InputLabel>
            <Select
              multiple
              name="serviceTypes"
              value={editedHotel.serviceTypes || []}
              onChange={handleChange}
              renderValue={(selected) => selected.join(', ')}
            >
              {serviceTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Hotel Color"
            name="color"
            type="color"
            value={editedHotel.color || '#000000'}
            onChange={handleChange}
            required
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Save Changes
        </Button>
        <Button onClick={() => onDelete(editedHotel.id)} color="error" variant="contained">
          Delete Hotel
        </Button>
      </Box>
    </Box>
  );
};

export default EditHotelForm;
