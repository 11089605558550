import React, { useState, useEffect, useMemo } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Chip, Box, FormControl, InputLabel, Select, MenuItem, TextField, Grid, Paper } from '@mui/material';
import { collection, query, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';

const localizer = momentLocalizer(moment);

const ShiftCalendar = ({ onShiftAction }) => {
  const [shifts, setShifts] = useState([]);
  const [hotels, setHotels] = useState({});
  const [selectedShift, setSelectedShift] = useState(null);
  const [filterHotel, setFilterHotel] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const [filterStartDate, setFilterStartDate] = useState('');
  const [filterEndDate, setFilterEndDate] = useState('');

  useEffect(() => {
    fetchShifts();
    fetchHotels();
  }, []);

  const fetchShifts = async () => {
    try {
      const shiftsQuery = query(collection(db, 'diensten'));
      const shiftsSnapshot = await getDocs(shiftsQuery);
      const shiftsData = shiftsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setShifts(shiftsData);
    } catch (error) {
      console.error('Error fetching shifts:', error);
    }
  };

  const fetchHotels = async () => {
    try {
      const hotelsQuery = query(collection(db, 'users'));
      const hotelsSnapshot = await getDocs(hotelsQuery);
      const hotelsData = hotelsSnapshot.docs
        .filter(doc => doc.data().role === 'hotel')
        .reduce((acc, doc) => {
          const data = doc.data();
          acc[doc.id] = { name: data.name, color: data.color || '#000000' };
          return acc;
        }, {});
      setHotels(hotelsData);
    } catch (error) {
      console.error('Error fetching hotels:', error);
    }
  };

  const filteredEvents = useMemo(() => {
    return shifts.filter(shift => {
      const matchesHotel = filterHotel === 'all' || shift.hotelId === filterHotel;
      const matchesStatus = filterStatus === 'all' || shift.status === filterStatus;
      const shiftStart = moment(shift.start.toDate());
      const matchesDateRange = (!filterStartDate || shiftStart.isSameOrAfter(filterStartDate, 'day')) &&
                                (!filterEndDate || shiftStart.isSameOrBefore(filterEndDate, 'day'));
      return matchesHotel && matchesStatus && matchesDateRange;
    }).map(shift => {
      const startDate = moment(shift.start.toDate());
      return {
        ...shift,
        start: startDate.toDate(),
        end: startDate.clone().add(1, 'hour').toDate(), // Set a 1-hour duration for display purposes
        title: `${hotels[shift.hotelId]?.name || shift.hotelName || 'Unknown Hotel'} - ${shift.type}`,
      };
    });
  }, [shifts, hotels, filterHotel, filterStatus, filterStartDate, filterEndDate]);

  const handleSelectEvent = (event) => {
    setSelectedShift(event);
  };

  const handleCloseDialog = () => {
    setSelectedShift(null);
  };

  const handleShiftAction = async (action) => {
    try {
      await updateDoc(doc(db, 'diensten', selectedShift.id), { status: action });
      onShiftAction(selectedShift.id, action);
      setSelectedShift(prevShift => ({ ...prevShift, status: action }));
      fetchShifts();
    } catch (error) {
      console.error('Error updating shift:', error);
    }
  };

  const eventStyleGetter = (event) => {
    const hotelColor = hotels[event.hotelId]?.color || event.hotelColor || '#000000';
    return {
      style: {
        backgroundColor: hotelColor,
        borderRadius: '5px',
        opacity: 0.8,
        color: 'white',
        border: 'none',
        display: 'block'
      }
    };
  };

  const customDayPropGetter = (date) => {
    return {
      style: {
        backgroundColor: '#f0f0f0',
      }
    };
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 3 }}>
      <Typography variant="h4" gutterBottom>Shift Calendar</Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel>Filter by Hotel</InputLabel>
            <Select value={filterHotel} onChange={(e) => setFilterHotel(e.target.value)}>
              <MenuItem value="all">All Hotels</MenuItem>
              {Object.entries(hotels).map(([id, hotel]) => (
                <MenuItem key={id} value={id}>{hotel.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel>Filter by Status</InputLabel>
            <Select value={filterStatus} onChange={(e) => setFilterStatus(e.target.value)}>
              <MenuItem value="all">All Statuses</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="approved">Approved</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Start Date"
            type="date"
            value={filterStartDate}
            onChange={(e) => setFilterStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="End Date"
            type="date"
            value={filterEndDate}
            onChange={(e) => setFilterEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Paper elevation={3} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        <Box sx={{ flexGrow: 1, minHeight: 0, overflow: 'auto', p: 2 }}>
          <Calendar
            localizer={localizer}
            events={filteredEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: '100%', minHeight: 500 }}
            onSelectEvent={handleSelectEvent}
            eventPropGetter={eventStyleGetter}
            dayPropGetter={customDayPropGetter}
            defaultView="month"
            views={['month', 'week', 'day']}
          />
        </Box>
      </Paper>
      {selectedShift && (
        <Dialog open={true} onClose={handleCloseDialog}>
          <DialogTitle>{`${hotels[selectedShift.hotelId]?.name || selectedShift.hotelName || 'Unknown Hotel'} - ${selectedShift.type}`}</DialogTitle>
          <DialogContent>
            <Typography><strong>Date:</strong> {moment(selectedShift.start).format('MMMM D, YYYY')}</Typography>
            <Typography><strong>Time:</strong> {`${moment(selectedShift.start).format('HH:mm')} - ${moment(selectedShift.end).format('HH:mm')}`}</Typography>
            <Typography>
              <strong>Status:</strong> 
              <Chip 
                label={selectedShift.status} 
                color={selectedShift.status === 'approved' ? 'success' : selectedShift.status === 'rejected' ? 'error' : 'default'}
                sx={{ ml: 1 }}
              />
            </Typography>
            {selectedShift.notes && (
              <Typography sx={{ mt: 2 }}><strong>Notes:</strong> {selectedShift.notes}</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Close</Button>
            <Button onClick={() => handleShiftAction('approved')} color="success" variant="contained">Approve</Button>
            <Button onClick={() => handleShiftAction('rejected')} color="error" variant="contained">Reject</Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default ShiftCalendar;
