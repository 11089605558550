import React from 'react';
import { TableRow, TableCell, Avatar, IconButton, useMediaQuery, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const AccountTableRow = ({ account, setEditingAccount, setOpenDialog, handleDeleteAccount }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TableRow hover>
      <TableCell>
        <Avatar src={account.profilePictureUrl} alt={account.name || account.email} />
      </TableCell>
      <TableCell>{account.name || 'N/A'}</TableCell>
      {!isMobile && <TableCell>{account.email}</TableCell>}
      <TableCell>{account.role}</TableCell>
      <TableCell>{account.rate ? `€${account.rate}/uur` : 'N/A'}</TableCell>
      <TableCell>
        <IconButton onClick={() => { setEditingAccount(account); setOpenDialog(true); }}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => handleDeleteAccount(account.id)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default AccountTableRow;
