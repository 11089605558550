import React from 'react';

function ShiftList({ shifts, onClose }) {
  return (
    <div className="shift-list">
      <h2>Geselecteerde diensten</h2>
      <ul>
        {shifts.map((shift, index) => (
          <li key={index}>{shift.name} - {shift.date}</li>
        ))}
      </ul>
      <button onClick={onClose}>Sluiten</button>
    </div>
  );
}

export default ShiftList;
