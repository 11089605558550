import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, FormControl, InputLabel, Select, MenuItem, IconButton, Box } from '@mui/material';
import { setDoc, doc, updateDoc } from 'firebase/firestore';
import { db, auth, storage } from '../../firebase';
import { createUserWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const AccountDialog = ({ openDialog, setOpenDialog, editingAccount, setEditingAccount, fetchAccounts, setSnackbar, hotels, user }) => {
  const [account, setAccount] = useState({
    email: '',
    password: '',
    name: '',
    role: 'user',
    rate: '',
    hotelId: '',
    color: '',
    location: '',
    serviceTypes: [],
  });
  const [showPassword, setShowPassword] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [hotelBanner, setHotelBanner] = useState(null);

  useEffect(() => {
    if (editingAccount) {
      setAccount(editingAccount);
    } else {
      setAccount({
        email: '',
        password: '',
        name: '',
        role: 'user',
        rate: '',
        hotelId: '',
        color: '',
        location: '',
        serviceTypes: [],
      });
    }
  }, [editingAccount]);

  const handleAddOrUpdateAccount = async () => {
    try {
      let userId = editingAccount ? editingAccount.id : null;

      if (!editingAccount) {
        const userCredential = await createUserWithEmailAndPassword(auth, account.email, account.password);
        userId = userCredential.user.uid;
      }

      const profilePictureUrl = await uploadFile(profilePicture, `personnel-photos/${userId}`);
      const companyLogoUrl = await uploadFile(companyLogo, `hotel-profiles/${userId}/logo`);
      const imageUrl = await uploadFile(hotelBanner, `hotel-profiles/${userId}/banner`);

      const accountData = {
        ...account,
        id: userId,
        profilePictureUrl: profilePictureUrl || account.profilePictureUrl,
        companyLogoUrl: companyLogoUrl || account.companyLogoUrl,
        imageUrl: imageUrl || account.imageUrl,
        createdAt: editingAccount ? account.createdAt : new Date(),
      };

      if (editingAccount) {
        await updateDoc(doc(db, "users", userId), accountData);
        if (account.password) {
          await updatePassword(auth.currentUser, account.password);
        }
      } else {
        await setDoc(doc(db, "users", userId), accountData);
      }

      setSnackbar({ open: true, message: editingAccount ? 'Account bijgewerkt' : 'Account aangemaakt', severity: 'success' });
      fetchAccounts();
      setOpenDialog(false);
    } catch (error) {
      console.error("Fout bij het aanmaken/bijwerken van account:", error);
      setSnackbar({ open: true, message: 'Fout bij het aanmaken/bijwerken van account', severity: 'error' });
    }
  };

  const uploadFile = async (file, path) => {
    if (!file) return null;
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const handleFieldChange = (field, value) => {
    setAccount(prevAccount => ({ ...prevAccount, [field]: value }));
  };

  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>{editingAccount ? 'Account bewerken' : 'Nieuw account'}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          label="E-mail"
          value={account.email}
          onChange={(e) => handleFieldChange('email', e.target.value)}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Wachtwoord"
          type={showPassword ? 'text' : 'password'}
          value={account.password}
          onChange={(e) => handleFieldChange('password', e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            ),
          }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Naam"
          value={account.name}
          onChange={(e) => handleFieldChange('name', e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Rol</InputLabel>
          <Select
            value={account.role}
            onChange={(e) => handleFieldChange('role', e.target.value)}
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="hotel">Hotel</MenuItem>
            <MenuItem value="user">Gebruiker</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          margin="normal"
          label="Tarief"
          value={account.rate}
          onChange={(e) => handleFieldChange('rate', e.target.value)}
        />
        {account.role === 'hotel' && (
          <>
            <TextField
              fullWidth
              margin="normal"
              label="Locatie"
              value={account.location}
              onChange={(e) => handleFieldChange('location', e.target.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Kleur"
              value={account.color}
              onChange={(e) => handleFieldChange('color', e.target.value)}
            />
          </>
        )}
        <Box mt={2}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="profile-picture-upload"
            type="file"
            onChange={(e) => setProfilePicture(e.target.files[0])}
          />
          <label htmlFor="profile-picture-upload">
            <Button variant="contained" component="span">
              Profielfoto uploaden
            </Button>
          </label>
        </Box>
        {account.role === 'hotel' && (
          <>
            <Box mt={2}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="company-logo-upload"
                type="file"
                onChange={(e) => setCompanyLogo(e.target.files[0])}
              />
              <label htmlFor="company-logo-upload">
                <Button variant="contained" component="span">
                  Hotellogo uploaden
                </Button>
              </label>
            </Box>
            <Box mt={2}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="hotel-banner-upload"
                type="file"
                onChange={(e) => setHotelBanner(e.target.files[0])}
              />
              <label htmlFor="hotel-banner-upload">
                <Button variant="contained" component="span">
                  Hotelbanner uploaden
                </Button>
              </label>
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)}>Annuleren</Button>
        <Button onClick={handleAddOrUpdateAccount} color="primary">
          {editingAccount ? 'Wijzigingen opslaan' : 'Account aanmaken'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccountDialog;