import React, { useState, useEffect } from 'react';
import { Typography, Box, Avatar, CircularProgress, Button, Paper } from '@mui/material';
import moment from 'moment';
import { db } from '../../firebase'; // Aangepast pad
import { doc, getDoc } from 'firebase/firestore';

const UpcomingShifts = ({ shifts = [] }) => {
  const [personnelDetails, setPersonnelDetails] = useState({});
  const [showAllShifts, setShowAllShifts] = useState(false);

  useEffect(() => {
    const fetchPersonnelDetails = async () => {
      const details = {};
      for (const shift of shifts) {
        if (shift.assignedPersonnel && !details[shift.assignedPersonnel]) {
          try {
            const personnelDoc = await getDoc(doc(db, 'personnel', shift.assignedPersonnel));
            if (personnelDoc.exists()) {
              const data = personnelDoc.data();
              details[shift.assignedPersonnel] = {
                name: data.name,
                photoURL: data.photoURL
              };
            }
          } catch (error) {
            console.error(`Error fetching personnel details for ID ${shift.assignedPersonnel}:`, error);
          }
        }
      }
      setPersonnelDetails(details);
    };

    fetchPersonnelDetails();
  }, [shifts]);

  const formatDate = (date) => {
    if (!date) return null;
    return moment(date);
  };

  const visibleShifts = showAllShifts ? shifts : shifts.slice(0, 3);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Typography variant="h6" gutterBottom>Aankomende Diensten</Typography>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', pr: 1 }}>
        {visibleShifts.map((shift, index) => {
          const startDate = formatDate(shift.start);
          const endDate = formatDate(shift.end);
          const personnel = personnelDetails[shift.assignedPersonnel];

          return (
            <Paper 
              key={index} 
              elevation={2}
              sx={{ 
                p: 2, 
                mb: 2, 
                borderRadius: 2,
                backgroundColor: 'background.paper'
              }}
            >
              <Typography variant="subtitle1">{shift.type || 'Onbekend type dienst'}</Typography>
              <Typography variant="body2" color="text.secondary">
                {startDate && endDate 
                  ? `${startDate.format('D MMM YYYY HH:mm')} - ${endDate.format('HH:mm')}`
                  : 'Ongeldige datum'}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {shift.hotelName || 'Onbekend hotel'}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                {personnel ? (
                  <>
                    <Avatar
                      src={personnel.photoURL}
                      alt={personnel.name}
                      sx={{ mr: 1, width: 24, height: 24 }}
                    />
                    <Typography variant="body2">
                      Toegewezen: {personnel.name}
                    </Typography>
                  </>
                ) : shift.assignedPersonnel ? (
                  <CircularProgress size={20} sx={{ mr: 1 }} />
                ) : (
                  <Typography variant="body2">
                    Niet toegewezen
                  </Typography>
                )}
              </Box>
              {shift.notes && (
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  Notities: {shift.notes}
                </Typography>
              )}
            </Paper>
          );
        })}
      </Box>
      {shifts.length > 3 && (
        <Button 
          onClick={() => setShowAllShifts(!showAllShifts)} 
          sx={{ mt: 2 }}
        >
          {showAllShifts ? 'Toon minder diensten' : 'Laat meer diensten zien'}
        </Button>
      )}
    </Box>
  );
};

export default UpcomingShifts;
