import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Box, Typography, Grid, Paper, useMediaQuery, useTheme, IconButton, Button } from '@mui/material';
import { AppContext } from '../contexts/AppContext';
import { ShiftContext } from '../contexts/ShiftContext';
import { addDoc, collection, Timestamp, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from '../firebase';
import moment from 'moment';
import 'moment/locale/nl';
import '../styles/Agenda.css';
import Calendar from './Calendar';
import ShiftSidebar from './ShiftSidebar';
import ShiftConfirmationModal from './ShiftConfirmationModal';
import MultiSelectShiftPopup from './MultiSelectShiftPopup';
import PendingShiftPopup from './PendingShiftPopup';
import ApprovedShiftPopup from './ApprovedShiftPopup';
import MobileCalendar from './MobileCalendar';
import ShiftList from './ShiftList';

moment.locale('nl');

const StyledPaper = Paper;

const Agenda = () => {
  const { user } = useContext(AppContext);
  const { shifts, setShifts } = useContext(ShiftContext);
  const [showModal, setShowModal] = useState(false);
  const [newEvents, setNewEvents] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedShift, setSelectedShift] = useState(null);
  const [isMultiSelectPopupOpen, setIsMultiSelectPopupOpen] = useState(false);
  const [isPendingPopupOpen, setIsPendingPopupOpen] = useState(false);
  const [isApprovedPopupOpen, setIsApprovedPopupOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [mobileNewEvents, setMobileNewEvents] = useState([]);
  const [selectedShifts, setSelectedShifts] = useState([]);
  const [showShiftList, setShowShiftList] = useState(false);

  const handleSelectSlot = ({ start }) => {
    const newEvent = {
      date: start,
      type: 'Nachtdienst',
      startTime: '22:45',
      endTime: '06:45',
      count: 1,
      notes: '',
      tempId: start.getTime(),
    };
    setNewEvents(prev => [...prev, newEvent]);
  };

  const handleCreateEvents = async () => {
    const eventsToCreate = isMobile ? mobileNewEvents : newEvents;
    if (user && user.hotelId && eventsToCreate.length > 0) {
      try {
        const createdEvents = await Promise.all(eventsToCreate.map(async (event) => {
          const startDateTime = moment(event.date).set({
            hour: parseInt(event.startTime.split(':')[0]),
            minute: parseInt(event.startTime.split(':')[1])
          });
          const endDateTime = moment(event.date).add(1, 'day').set({
            hour: parseInt(event.endTime.split(':')[0]),
            minute: parseInt(event.endTime.split(':')[1])
          });

          const eventData = {
            type: event.type,
            start: Timestamp.fromDate(startDateTime.toDate()),
            end: Timestamp.fromDate(endDateTime.toDate()),
            userId: user.uid,
            hotelId: user.hotelId,
            hotelName: user.name,
            status: 'pending',
            count: event.count,
            notes: event.notes,
            createdAt: Timestamp.now()
          };

          const docRef = await addDoc(collection(db, "diensten"), eventData);

          return {
            id: docRef.id,
            ...eventData,
            start: startDateTime.toDate(),
            end: endDateTime.toDate(),
          };
        }));

        // Gebruik een Set om dubbele diensten te voorkomen
        const uniqueCreatedEvents = Array.from(new Set(createdEvents.map(e => e.start.toISOString()))).map(dateString => 
          createdEvents.find(e => e.start.toISOString() === dateString)
        );

        setShifts(prevShifts => {
          const newShifts = [...prevShifts];
          uniqueCreatedEvents.forEach(newShift => {
            const existingShiftIndex = newShifts.findIndex(shift => 
              moment(shift.start).isSame(newShift.start, 'day')
            );
            if (existingShiftIndex === -1) {
              newShifts.push(newShift);
            }
          });
          return newShifts;
        });

        if (isMobile) {
          setMobileNewEvents([]);
          setSelectedDates([]);
        } else {
          setNewEvents([]);
        }
        setShowModal(false);
      } catch (error) {
        console.error("Error creating events:", error);
        alert("Er is een fout opgetreden bij het aanmaken van de diensten. Probeer het opnieuw.");
      }
    } else {
      alert("Er ontbreken gegevens om de diensten aan te maken. Controleer of u bent ingelogd en probeer het opnieuw.");
    }
  };

  const handleRemoveEvent = (tempId) => {
    setNewEvents(prev => prev.filter(event => event.tempId !== tempId));
  };

  const handleSelectEvent = (event) => {
    setSelectedShift(event);
    if (event.status === 'pending') {
      setIsPendingPopupOpen(true);
    } else if (event.status === 'approved') {
      setIsApprovedPopupOpen(true);
    } else {
      setIsMultiSelectPopupOpen(true);
    }
  };

  const handleUpdateShift = async (updatedShift) => {
    try {
      // Update de dienst in Firestore
      const shiftRef = doc(db, "diensten", updatedShift.id);
      await updateDoc(shiftRef, {
        notes: updatedShift.notes,
        // Voeg hier andere velden toe die mogelijk zijn bijgewerkt
      });

      // Update de lokale state
      setShifts(prevShifts => prevShifts.map(shift => 
        shift.id === updatedShift.id ? updatedShift : shift
      ));
    } catch (error) {
      console.error("Fout bij het bijwerken van de dienst:", error);
      alert("Er is een fout opgetreden bij het opslaan van de wijzigingen. Probeer het opnieuw.");
    }
  };

  const handleDeleteShift = async (shiftId) => {
    try {
      // Verwijder de dienst uit Firestore
      await deleteDoc(doc(db, "diensten", shiftId));

      // Update de lokale state
      setShifts(prevShifts => prevShifts.filter(shift => shift.id !== shiftId));
    } catch (error) {
      console.error("Fout bij het verwijderen van de dienst:", error);
      alert("Er is een fout opgetreden bij het verwijderen van de dienst. Probeer het opnieuw.");
    }
  };

  const handleRequestChange = async (shiftId, changeRequest) => {
    try {
      // Update the shift in Firestore with the change request
      // This part depends on your Firestore structure and update logic
      // For now, we'll just update the local state
      setShifts(prevShifts => prevShifts.map(shift => 
        shift.id === shiftId ? { ...shift, changeRequest, status: 'change_requested' } : shift
      ));
    } catch (error) {
      console.error("Error requesting change:", error);
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let style = {
      backgroundColor: '#e0e0e0',
      color: '#333333',
      border: 'none',
      display: 'block'
    };

    if (newEvents.some(e => moment(e.date).isSame(event.start, 'day'))) {
      style.backgroundColor = '#e0e0e0';
      style.color = '#333333';
    }

    if (event.status === 'pending') {
      style.backgroundColor = '#ff9800';
      style.color = '#ffffff';
    }

    if (event.status === 'approved') {
      style.backgroundColor = '#4caf50';
      style.color = '#ffffff';
    }

    if (isSelected) {
      style.border = '2px solid #2c3e50';
    }

    // Voeg een indicator toe voor diensten die doorlopen tot de volgende dag
    if (moment(event.end).isAfter(moment(event.start).endOf('day'))) {
      style.borderRight = '4px solid #ff9800';
    }

    return { style };
  };

  const dayPropGetter = (date) => {
    return {
      style: {
        backgroundColor: 'white',
        transition: 'background-color 0.2s',
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
      },
    };
  };

  const formatEventsForCalendar = (eventsToFormat) => {
    return eventsToFormat.map(event => {
      let formattedEvent = { ...event };
      
      try {
        if (event.start?.toDate) {
          formattedEvent.start = event.start.toDate();
        } else if (!(event.start instanceof Date)) {
          formattedEvent.start = new Date(event.start);
        }

        if (event.end?.toDate) {
          formattedEvent.end = event.end.toDate();
        } else if (!(event.end instanceof Date)) {
          formattedEvent.end = new Date(event.end);
        }

        // Zet de einddatum gelijk aan de startdatum voor weergavedoeleinden
        formattedEvent.end = new Date(formattedEvent.start);
        formattedEvent.end.setHours(23, 59, 59);

      } catch (error) {
        console.error("Error formatting event:", event, error);
        formattedEvent.start = new Date();
        formattedEvent.end = new Date();
      }

      return formattedEvent;
    });
  };

  const combinedEvents = [
    ...formatEventsForCalendar(shifts),
    ...newEvents.map(e => ({
      ...e,
      start: moment(e.date).toDate(),
      end: moment(e.date).endOf('day').toDate(), // Zet einde op einde van de dag
      title: 'Nieuwe Dienst',
    }))
  ].filter((event, index, self) =>
    index === self.findIndex((t) => t.start.getTime() === event.start.getTime())
  );

  const handleMobileSelectDate = (date) => {
    setSelectedDates(prev => {
      const dateString = date.toISOString().split('T')[0];
      return prev.includes(dateString) ? prev.filter(d => d !== dateString) : [...prev, dateString];
    });
  };

  const handleConfirmSelectedDates = () => {
    const newShifts = Array.from(new Set(selectedDates)).map(dateString => ({
      date: new Date(dateString),
      type: 'Nachtdienst',
      startTime: '22:45',
      endTime: '06:45',
      count: 1,
      notes: '',
      tempId: new Date(dateString).getTime(),
    }));
    setMobileNewEvents(prevShifts => {
      const uniqueShifts = [...prevShifts];
      newShifts.forEach(newShift => {
        if (!uniqueShifts.some(shift => moment(shift.date).isSame(newShift.date, 'day'))) {
          uniqueShifts.push(newShift);
        }
      });
      return uniqueShifts;
    });
    setShowModal(true);
  };

  const toggleShift = (shift) => {
    setSelectedShifts(prevShifts => 
      prevShifts.includes(shift)
        ? prevShifts.filter(s => s !== shift)
        : [...prevShifts, shift]
    );
  };

  const handleRequestShifts = (dates) => {
    // Implementeer hier de logica voor het opvragen van diensten
    console.log('Diensten opvragen voor:', dates);
    // Hier kunt u bijvoorbeeld een Firebase-functie aanroepen om de diensten op te halen of op te slaan
  };

  return (
    <Box className="user-dashboard" sx={{ p: 3, minHeight: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h4" gutterBottom className="typography-heading">
        Welkom, {user?.name || 'Gebruiker'}
      </Typography>
      <Grid container spacing={3} sx={{ flexGrow: 1, mb: 3, overflow: 'hidden' }}>
        <Grid item xs={12} md={isMobile ? 12 : 9} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <StyledPaper className="calendar-container">
            {isMobile ? (
              <MobileCalendar
                events={combinedEvents}
                onSelectDate={handleMobileSelectDate}
                selectedDates={selectedDates}
                onShiftToggle={toggleShift}
                onRequestShifts={handleRequestShifts}
              />
            ) : (
              <Calendar
                events={combinedEvents}
                onSelectSlot={handleSelectSlot}
                onSelectEvent={handleSelectEvent}
                eventStyleGetter={eventStyleGetter}
                dayPropGetter={dayPropGetter}
              />
            )}
          </StyledPaper>
        </Grid>
        {!isMobile && (
          <Grid item xs={12} md={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <ShiftSidebar 
              newEvents={newEvents}
              onRemoveEvent={handleRemoveEvent}
              onCreateEvents={() => setShowModal(true)}
            />
          </Grid>
        )}
      </Grid>

      <ShiftConfirmationModal 
        show={showModal}
        shifts={isMobile ? mobileNewEvents : newEvents}
        onConfirm={handleCreateEvents}
        onClose={() => {
          setShowModal(false);
          if (isMobile) {
            setSelectedDates([]);
            setMobileNewEvents([]);
          }
        }}
        onDeleteShift={(tempId) => {
          if (isMobile) {
            setMobileNewEvents(prev => prev.filter(shift => shift.tempId !== tempId));
          } else {
            handleRemoveEvent(tempId);
          }
        }}
        onUpdateShift={(index, updatedShift) => {
          if (isMobile) {
            setMobileNewEvents(prev => prev.map((shift, i) => i === index ? updatedShift : shift));
          } else {
            setNewEvents(prev => prev.map((shift, i) => i === index ? updatedShift : shift));
          }
        }}
      />

      <MultiSelectShiftPopup
        open={isMultiSelectPopupOpen}
        onClose={() => setIsMultiSelectPopupOpen(false)}
        shift={selectedShift}
        onUpdate={handleUpdateShift}
        onDelete={handleDeleteShift}
      />

      <PendingShiftPopup
        open={isPendingPopupOpen}
        onClose={() => setIsPendingPopupOpen(false)}
        shift={selectedShift}
        onUpdate={handleUpdateShift}
        onDelete={handleDeleteShift}
      />

      <ApprovedShiftPopup
        open={isApprovedPopupOpen}
        onClose={() => setIsApprovedPopupOpen(false)}
        shift={selectedShift}
        onRequestChange={handleRequestChange}
      />

      {isMobile && (
        <Box sx={{ position: 'fixed', bottom: 20, right: 20, zIndex: 1000 }}>
          <ShiftSidebar 
            newEvents={newEvents}
            onRemoveEvent={handleRemoveEvent}
            onCreateEvents={() => setShowModal(true)}
            isMobile={true}
          />
        </Box>
      )}

      {isMobile && (
        <Box sx={{ position: 'fixed', bottom: 20, right: 20, zIndex: 1000 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowShiftList(true)}
            disabled={selectedShifts.length === 0}
          >
            Geselecteerde diensten ({selectedShifts.length})
          </Button>
        </Box>
      )}

      {showShiftList && (
        <ShiftList
          shifts={selectedShifts}
          onClose={() => setShowShiftList(false)}
        />
      )}

      {isMobile && (
        <Box sx={{ position: 'fixed', bottom: 80, right: 20, zIndex: 1000 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirmSelectedDates}
            disabled={selectedDates.length === 0}
          >
            Bevestig geselecteerde datums ({selectedDates.length})
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Agenda;
