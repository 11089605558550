import React, { useState, useContext } from 'react';
import { Box, Typography, TextField, Button, Switch, FormControlLabel, Divider } from '@mui/material';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { AppContext } from '../contexts/AppContext';

const Settings = () => {
  const { user, setUser } = useContext(AppContext);
  const [settings, setSettings] = useState({
    emailNotifications: user.emailNotifications || false,
    darkMode: user.darkMode || false,
    language: user.language || 'en',
  });

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: value !== undefined ? value : checked,
    }));
  };

  const handleSave = async () => {
    try {
      await updateDoc(doc(db, 'users', user.uid), settings);
      setUser(prevUser => ({ ...prevUser, ...settings }));
      alert('Settings saved successfully!');
    } catch (error) {
      console.error('Error saving settings:', error);
      alert('Error saving settings. Please try again.');
    }
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 4 }}>
      <Typography variant="h4" gutterBottom>Settings</Typography>
      <Divider sx={{ my: 2 }} />
      
      <FormControlLabel
        control={
          <Switch
            checked={settings.emailNotifications}
            onChange={handleChange}
            name="emailNotifications"
          />
        }
        label="Email Notifications"
      />
      
      <FormControlLabel
        control={
          <Switch
            checked={settings.darkMode}
            onChange={handleChange}
            name="darkMode"
          />
        }
        label="Dark Mode"
      />
      
      <TextField
        select
        label="Language"
        value={settings.language}
        onChange={handleChange}
        name="language"
        fullWidth
        margin="normal"
      >
        <option value="en">English</option>
        <option value="es">Spanish</option>
        <option value="fr">French</option>
      </TextField>
      
      <Button variant="contained" color="primary" onClick={handleSave} sx={{ mt: 2 }}>
        Save Settings
      </Button>
    </Box>
  );
};

export default Settings;
