import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CostDiagram = () => {
  // Voorbeeld data, vervang dit door echte data uit uw state of props
  const data = [
    { name: 'Jan', kosten: 4000 },
    { name: 'Feb', kosten: 3000 },
    { name: 'Mrt', kosten: 2000 },
    { name: 'Apr', kosten: 2780 },
    // ... meer maanden
  ];

  return (
    <div style={{ width: '100%', height: '100%', minHeight: '400px' }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="kosten" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CostDiagram;
