import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, TextField, Button, Switch, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import { collection, query, where, getDocs, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { db, auth } from '../firebase';
import { AppContext } from '../contexts/AppContext';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const HotelSettings = () => {
  const { user } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [newUser, setNewUser] = useState({ name: '', email: '', password: '', showRate: true });

  useEffect(() => {
    if (user && user.hotelId) {
      fetchUsers();
    }
  }, [user]);

  const fetchUsers = async () => {
    const q = query(collection(db, "users"), where("hotelId", "==", user.hotelId));
    const querySnapshot = await getDocs(q);
    setUsers(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleCreateUser = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, newUser.email, newUser.password);
      await addDoc(collection(db, "users"), {
        uid: userCredential.user.uid,
        name: newUser.name,
        email: newUser.email,
        role: 'user',
        hotelId: user.hotelId,
        showRate: newUser.showRate
      });
      setOpenDialog(false);
      setNewUser({ name: '', email: '', password: '', showRate: true });
      fetchUsers();
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  const handleUpdateUser = async () => {
    try {
      await updateDoc(doc(db, "users", editingUser.id), {
        name: editingUser.name,
        showRate: editingUser.showRate
      });
      setOpenDialog(false);
      setEditingUser(null);
      fetchUsers();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await deleteDoc(doc(db, "users", userId));
      fetchUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Hotel Settings</Typography>
      <Button variant="contained" onClick={() => setOpenDialog(true)} sx={{ mb: 2 }}>
        Add New User
      </Button>
      <List>
        {users.map((user) => (
          <ListItem key={user.id}>
            <ListItemText 
              primary={user.name} 
              secondary={`Email: ${user.email} | Show Rate: ${user.showRate ? 'Yes' : 'No'}`} 
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => {
                setEditingUser(user);
                setOpenDialog(true);
              }}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteUser(user.id)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Dialog open={openDialog} onClose={() => {
        setOpenDialog(false);
        setEditingUser(null);
        setNewUser({ name: '', email: '', password: '', showRate: true });
      }}>
        <DialogTitle>{editingUser ? 'Edit User' : 'Create New User'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={editingUser ? editingUser.name : newUser.name}
            onChange={(e) => editingUser ? setEditingUser({...editingUser, name: e.target.value}) : setNewUser({...newUser, name: e.target.value})}
          />
          {!editingUser && (
            <>
              <TextField
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                value={newUser.email}
                onChange={(e) => setNewUser({...newUser, email: e.target.value})}
              />
              <TextField
                margin="dense"
                label="Password"
                type="password"
                fullWidth
                value={newUser.password}
                onChange={(e) => setNewUser({...newUser, password: e.target.value})}
              />
            </>
          )}
          <FormControlLabel
            control={
              <Switch
                checked={editingUser ? editingUser.showRate : newUser.showRate}
                onChange={(e) => editingUser ? setEditingUser({...editingUser, showRate: e.target.checked}) : setNewUser({...newUser, showRate: e.target.checked})}
              />
            }
            label="Show Rate"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpenDialog(false);
            setEditingUser(null);
            setNewUser({ name: '', email: '', password: '', showRate: true });
          }}>Cancel</Button>
          <Button onClick={editingUser ? handleUpdateUser : handleCreateUser}>
            {editingUser ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HotelSettings;
