import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BusinessIcon from '@mui/icons-material/Business';
import { AppContext } from '../contexts/AppContext';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // Voeg deze import toe
import MenuBookIcon from '@mui/icons-material/MenuBook';

const Sidebar = () => {
  const { user } = useContext(AppContext);
  const location = useLocation();

  const menuItems = {
    admin: [
      { text: 'Dashboard', icon: <DashboardIcon />, link: '/admin' },
      { text: 'Manage Shifts', icon: <EventIcon />, link: '/admin/manage-shifts' },
      { text: 'Shift Calendar', icon: <CalendarTodayIcon />, link: '/admin/shift-calendar' },
      { text: 'Manage Hotels', icon: <BusinessIcon />, link: '/admin/manage-hotels' },
      { text: 'Personnel Manager', icon: <PeopleIcon />, link: '/admin/personnel-manager' },
      { text: 'Account Manager', icon: <BusinessIcon />, link: '/admin/account-manager' },
      { text: 'Hotel Rates', icon: <AttachMoneyIcon />, link: '/admin/hotel-rates' }, // Nieuw item
      { text: 'Hotel Wiki', icon: <MenuBookIcon />, link: '/admin/hotel-wiki' },
      { text: 'Settings', icon: <SettingsIcon />, link: '/settings' },
      { text: 'Diensten Uitvragen', icon: <CalendarTodayIcon />, link: '/admin/calendar' },
    ],
    hotel: [
      { text: 'Dashboard', icon: <DashboardIcon />, link: '/hotel' },
      { text: 'Agenda', icon: <EventIcon />, link: '/hotel/agenda' },
      { text: 'Manage Users', icon: <PeopleIcon />, link: '/hotel/manage-users' },
      { text: 'Settings', icon: <SettingsIcon />, link: '/settings' },
    ],
    user: [
      { text: 'Dashboard', icon: <DashboardIcon />, link: '/user' },
      { text: 'My Shifts', icon: <EventIcon />, link: '/user/shifts' },
      { text: 'Profile', icon: <PeopleIcon />, link: '/user/profile' },
      { text: 'Settings', icon: <SettingsIcon />, link: '/settings' },
    ],
  };

  return (
    <Box
      sx={{
        width: 300,
        height: '100vh',
        backgroundColor: 'white',
        color: '#333',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        backgroundImage: 'linear-gradient(to bottom, #f9f9f9, #fff)',
        overflow: 'hidden',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1200,
      }}
    >
      <Box sx={{ flexGrow: 1, overflow: 'auto', pt: '64px' }}> {/* Add top padding to account for TopBar */}
        <List sx={{ mt: 2 }}>
          {menuItems[user?.role]?.map((item, index) => (
            <ListItem
              button
              component={Link}
              to={item.link}
              key={index}
              selected={location.pathname === item.link}
              sx={{
                backgroundColor: location.pathname === item.link ? '#e0e0e0' : 'transparent',
                '&.Mui-selected': {
                  backgroundColor: '#e0e0e0',
                },
                '&:hover': {
                  backgroundColor: '#d3d3d3',
                },
                borderRadius: '8px',
                marginBottom: '8px',
                color: '#333',
              }}
            >
              <ListItemIcon sx={{ color: '#333' }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Box>

      <Divider sx={{ backgroundColor: 'rgba(0,0,0,0.1)' }} />

      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {user?.role === 'admin' ? (
          <>
            <img
              src="https://flexxhospitality.com/wp-content/uploads/2021/03/Asset-10@3x.png"
              alt="Flexx Hospitality Logo"
              style={{ width: 60, height: 'auto', marginBottom: 8 }}
            />
            <Typography variant="subtitle2" align="center">
              Flexx Hospitality
            </Typography>
          </>
        ) : (
          <>
            <img
              src={user?.companyLogoUrl || '/default-hotel-logo.png'}
              alt="Hotel logo"
              style={{ width: 60, height: 60, marginBottom: 8, borderRadius: '50%', objectFit: 'cover' }}
            />
            <Typography variant="subtitle2" align="center">
              {user?.name || 'Hotel Name'}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Sidebar;
