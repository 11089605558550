import React from 'react';
import { Box, TextField, Button, Tabs, Tab } from '@mui/material';

const AccountFilters = ({ searchTerm, handleSearch, setOpenDialog, tabValue, handleTabChange, viewMode, setViewMode }) => {
  return (
    <>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <TextField
          label="Zoeken"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          sx={{ width: '70%' }}
        />
        <Button variant="contained" onClick={() => setOpenDialog(true)}>
          Nieuw Account
        </Button>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Alle" />
          <Tab label="Admins" />
          <Tab label="Hotels" />
          <Tab label="Gebruikers" />
        </Tabs>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Button onClick={() => setViewMode('card')} variant={viewMode === 'card' ? 'contained' : 'outlined'}>
          Kaartweergave
        </Button>
        <Button onClick={() => setViewMode('table')} variant={viewMode === 'table' ? 'contained' : 'outlined'}>
          Tabelweergave
        </Button>
      </Box>
    </>
  );
};

export default AccountFilters;