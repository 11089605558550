import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area } from 'recharts';

const ShiftDiagram = () => {
  // Voorbeeld data, vervang dit door echte data uit uw state of props
  const data = [
    { name: 'Jan', diensten: 4 },
    { name: 'Feb', diensten: 3 },
    { name: 'Mrt', diensten: 2 },
    { name: 'Apr', diensten: 5 },
  ];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <defs>
          <linearGradient id="colorDiensten" x1="0" y1="0" x2="0" y2="1">
            <stop offset="50%" stopColor="#8884d8" stopOpacity={0.8}/>
            <stop offset="100%" stopColor="#8884d8" stopOpacity={0.1}/>
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Area type="monotone" dataKey="diensten" stroke="#8884d8" fillOpacity={1} fill="url(#colorDiensten)" />
        <Line type="monotone" dataKey="diensten" stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ShiftDiagram;
