import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

const ApprovedShiftPopup = ({ open, onClose, shift, onRequestChange }) => {
  const [changeRequest, setChangeRequest] = useState('');
  const [shiftDetails, setShiftDetails] = useState(null);

  useEffect(() => {
    if (shift) {
      setShiftDetails(shift);
    }
  }, [shift]);

  const handleRequestChange = () => {
    if (shiftDetails) {
      onRequestChange(shiftDetails.id, changeRequest);
    }
    onClose();
  };

  if (!shiftDetails) return null;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Approved Shift</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          label="Shift Type"
          value={shiftDetails.type || ''}
          InputProps={{ readOnly: true }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Start Time"
          value={shiftDetails.startTime || ''}
          InputProps={{ readOnly: true }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="End Time"
          value={shiftDetails.endTime || ''}
          InputProps={{ readOnly: true }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Notes"
          value={shiftDetails.notes || ''}
          InputProps={{ readOnly: true }}
          multiline
          rows={4}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Change Request"
          multiline
          rows={4}
          value={changeRequest}
          onChange={(e) => setChangeRequest(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={handleRequestChange} color="primary">Request Change</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApprovedShiftPopup;
