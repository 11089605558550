import React from 'react';
import { Drawer, Typography, List, ListItem, ListItemText, ListItemIcon, IconButton, Box, Chip } from '@mui/material';
import { Notifications, Close, Event, Note } from '@mui/icons-material';

const NotificationsModal = ({ open, onClose, notifications }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: { xs: '100%', sm: 400 } }
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Meldingen</Typography>
          <IconButton onClick={onClose} edge="end">
            <Close />
          </IconButton>
        </Box>
        <List>
          {notifications.map((notification) => (
            <ListItem key={notification.id}>
              <ListItemIcon>
                {notification.type === 'pending_shift' ? <Event color="warning" /> : <Note color="info" />}
              </ListItemIcon>
              <ListItemText 
                primary={notification.message}
                secondary={
                  <Chip 
                    label={notification.type === 'pending_shift' ? 'Openstaande Dienst' : 'Dienstnotitie'} 
                    size="small" 
                    color={notification.type === 'pending_shift' ? 'warning' : 'info'}
                  />
                }
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default NotificationsModal;
