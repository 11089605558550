import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, List, ListItem, ListItemText, ListItemAvatar, Avatar, CircularProgress, Button, Chip, Card, CardContent, IconButton, Badge, useTheme, useMediaQuery, Collapse } from '@mui/material';
import { collection, query, getDocs, where, onSnapshot, getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, LineChart, Line, ResponsiveContainer, Label } from 'recharts';
import QuickLinks from '../components/dashboard/QuickLinks';
import NotificationsModal from '../components/dashboard/NotificationsModal';
import HotelsOverzicht from '../components/adminDashboard/HotelsOverzicht';
import DienstenLijst from '../components/DienstenLijst';
import { TrendingUp } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const localizer = momentLocalizer(moment);

const AdminDashboard = () => {
  const [shifts, setShifts] = useState([]);
  const [stats, setStats] = useState({
    pendingShifts: 0,
    approvedShifts: 0,
    rejectedShifts: 0,
    totalRevenue: 0,
  });
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [notificationsModalOpen, setNotificationsModalOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const calculateShiftRevenue = (shift) => {
    const startTime = shift.start.toDate();
    const endTime = shift.realEnd ? shift.realEnd.toDate() : shift.end.toDate();
    const durationHours = (endTime - startTime) / (1000 * 60 * 60);
    const rate = parseFloat(shift.hotelRate) || 0; // Gebruik het hoteltarief
    return rate * durationHours;
  };

  const fetchShiftsWithRates = async () => {
    const shiftsQuery = query(collection(db, 'diensten'));
    const snapshot = await getDocs(shiftsQuery);
    const shiftsData = await Promise.all(snapshot.docs.map(async (shiftDoc) => {
      const shiftData = { id: shiftDoc.id, ...shiftDoc.data() };
      const hotelDocRef = doc(db, 'users', shiftData.hotelId);
      const hotelDocSnap = await getDoc(hotelDocRef);
      if (hotelDocSnap.exists()) {
        shiftData.hotelRate = hotelDocSnap.data().rate || 0;
      } else {
        shiftData.hotelRate = 0;
      }
      return shiftData;
    }));
    setShifts(shiftsData);
    calculateStats(shiftsData);
    setLoading(false);
  };

  const fetchNotifications = async () => {
    // Implementeer logica om meldingen op te halen
    const notifs = []; // Vervang dit door echte data
    setNotifications(notifs);
  };

  useEffect(() => {
    fetchShiftsWithRates();
    fetchNotifications();
  }, []);

  const calculateStats = (shiftsData) => {
    const newStats = shiftsData.reduce((acc, shift) => {
      acc[`${shift.status}Shifts`]++;
      acc.totalRevenue += calculateShiftRevenue(shift);
      return acc;
    }, { pendingShifts: 0, approvedShifts: 0, rejectedShifts: 0, totalRevenue: 0 });
    setStats(newStats);
  };

  const handleNotificationClick = () => {
    setNotificationsModalOpen(true);
  };

  if (loading) {
    return <CircularProgress />;
  }

  const calendarEvents = shifts.map(shift => ({
    title: `${shift.hotelName} - ${shift.type}`,
    start: shift.start.toDate(),
    end: shift.end.toDate(),
    resource: shift,
  }));

  const pendingShifts = shifts.filter(shift => shift.status === 'pending');
  const approvedShifts = shifts.filter(shift => shift.status === 'approved');
  const pastShifts = shifts.filter(shift => {
    const now = new Date();
    return shift.end.toDate() < now;
  });

  const pendingRevenue = pendingShifts.reduce((total, shift) => total + calculateShiftRevenue(shift), 0);
  const approvedRevenue = approvedShifts.reduce((total, shift) => total + calculateShiftRevenue(shift), 0);
  const pastRevenue = pastShifts.reduce((total, shift) => total + calculateShiftRevenue(shift), 0);

  const totalRevenue = pendingRevenue + approvedRevenue + pastRevenue;

  const revenueData = [
    { name: 'Lopend', value: pendingRevenue, fill: '#4CAF50' },
    { name: 'Goedgekeurd', value: approvedRevenue, fill: '#2196F3' },
    { name: 'Voltooid', value: pastRevenue, fill: '#FFC107' },
  ];

  const SOFT_COLORS = {
    pending: 'linear-gradient(45deg, #FFE0E6 30%, #FFD1DC 90%)',
    approved: 'linear-gradient(45deg, #E0F2F1 30%, #B2DFDB 90%)',
    rejected: 'linear-gradient(45deg, #FFF3E0 30%, #FFE0B2 90%)',
    total: 'linear-gradient(45deg, #E8F5E9 30%, #C8E6C9 90%)',
  };

  return (
    <Box sx={{ flexGrow: 1, p: isMobile ? 2 : 3 }} className="admin-dashboard">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4">Admin Dashboard</Typography>
        <IconButton onClick={handleNotificationClick}>
          <Badge badgeContent={notifications.length} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Box>

      <QuickLinks
        onQuickShiftClick={() => {/* Implementeer quick shift logica */}}
        onNotesClick={() => {/* Implementeer notities logica */}}
        onRosterClick={() => {/* Implementeer rooster logica */}}
        onAgendaClick={() => {/* Implementeer agenda klik logica */}}
      />
      
      <Box sx={{ mt: isMobile ? 4 : 3 }}>
        <Grid container spacing={isMobile ? 2 : 3}>
          <Grid item xs={12} md={3}>
            <Card sx={{ background: SOFT_COLORS.pending }}>
              <CardContent>
                <Typography variant="h6">Openstaande diensten</Typography>
                <Typography variant="h4">{stats.pendingShifts}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ background: SOFT_COLORS.approved }}>
              <CardContent>
                <Typography variant="h6">Goedgekeurde diensten</Typography>
                <Typography variant="h4">{stats.approvedShifts}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ background: SOFT_COLORS.rejected }}>
              <CardContent>
                <Typography variant="h6">Afgekeurde diensten</Typography>
                <Typography variant="h4">{stats.rejectedShifts}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3}>
            <Card sx={{ background: SOFT_COLORS.total }}>
              <CardContent>
                <Typography variant="h6">Totale omzet</Typography>
                <Typography variant="h4">€{stats.totalRevenue.toFixed(2)}</Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 2, height: isMobile ? 'auto' : 600, overflow: 'hidden' }}>
              <Typography variant="h6" gutterBottom>Dienstenoverzicht</Typography>
              <Box sx={{ height: isMobile ? 400 : 'calc(100% - 40px)', overflow: 'auto' }}>
                <DienstenLijst shifts={shifts} />
              </Box>
            </Paper>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Card sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              p: 2,
              boxSizing: 'border-box'
            }}>
              <Box sx={{ mb: 1 }}>
                <Typography variant="h6" align="center">
                  Omzetverdeling
                </Typography>
                <Typography variant="subtitle2" align="center" color="textSecondary">
                  Januari - Juni 2024
                </Typography>
              </Box>
              
              <Box sx={{ 
                flexGrow: 1, 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                my: 2
              }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={revenueData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      innerRadius="60%"
                      outerRadius="80%"
                      strokeWidth={0}
                    >
                      {revenueData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.fill} />
                      ))}
                      <Label
                        content={({ viewBox }) => {
                          const { cx, cy } = viewBox;
                          return (
                            <text x={cx} y={cy} textAnchor="middle" dominantBaseline="central">
                              <tspan x={cx} y={cy} dy="-0.5em" fontSize="24" fontWeight="bold" fill="#333">
                                €{totalRevenue.toFixed(2)}
                              </tspan>
                              <tspan x={cx} y={cy} dy="1.5em" fontSize="14" fill="#666">
                                Totale omzet
                              </tspan>
                            </text>
                          );
                        }}
                      />
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Box>
              
              <Box sx={{ mt: 'auto' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                  <TrendingUp fontSize="small" sx={{ color: '#4CAF50' }} />
                  <Typography variant="body2" fontWeight="medium">
                    5,2% stijging deze maand
                  </Typography>
                </Box>
                <Typography variant="caption" align="center" color="textSecondary" sx={{ display: 'block' }}>
                  Toont totale omzet voor de laatste 6 maanden
                </Typography>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2, height: '100%', overflow: 'hidden' }}>
              <Typography variant="h6" gutterBottom>Maandelijkse Statistieken</Typography>
              <Box sx={{ 
                width: '100%', 
                height: isMobile ? 300 : 400, 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center'
              }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={[
                      { name: 'Jan', diensten: 40 },
                      { name: 'Feb', diensten: 30 },
                      { name: 'Mar', diensten: 50 },
                      // ... voeg meer maanden toe
                    ]}
                    margin={{ top: 5, right: 5, left: 0, bottom: 5 }}
                  >
                    <XAxis dataKey="name" />
                    <YAxis width={40} tickFormatter={(value) => value.toLocaleString()} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="diensten" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2, height: '100%', overflow: 'hidden' }}>
              <Typography variant="h6" gutterBottom>Omzettrend</Typography>
              <Box sx={{ 
                width: '100%', 
                height: isMobile ? 300 : 400, 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center'
              }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    data={[
                      { name: 'Jan', omzet: 4000 },
                      { name: 'Feb', omzet: 3000 },
                      { name: 'Mar', omzet: 5000 },
                      // ... voeg meer maanden toe
                    ]}
                    margin={{ top: 5, right: 5, left: 0, bottom: 5 }}
                  >
                    <XAxis dataKey="name" />
                    <YAxis width={40} tickFormatter={(value) => value.toLocaleString()} />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="omzet" stroke="#8884d8" />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{ p: 2, overflow: 'hidden' }}>
              <Typography variant="h6" gutterBottom>Openstaande Diensten per Hotel</Typography>
              <HotelsOverzicht />
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <NotificationsModal
        open={notificationsModalOpen}
        onClose={() => setNotificationsModalOpen(false)}
        notifications={notifications}
      />
    </Box>
  );
};

export default AdminDashboard;
