import React from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Box, Typography, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { PendingActions as PendingIcon } from '@mui/icons-material';

moment.locale('nl');
const localizer = momentLocalizer(moment);

const Calendar = ({ events, onSelectSlot, onSelectEvent, eventStyleGetter, dayPropGetter }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CustomEvent = ({ event }) => (
    <Box className="service-list-item">
      <Typography variant="body2" style={{ color: '#ffffff', fontSize: isMobile ? '0.75rem' : '0.875rem' }}>
        {event.type} {isMobile ? '' : `${event.startTime || '22:45'} - ${event.endTime || '06:45'}`}
      </Typography>
      {event.status === 'pending' && (
        <Tooltip title="In afwachting van goedkeuring">
          <PendingIcon className="event-icon" fontSize="small" style={{ color: '#ffffff' }} />
        </Tooltip>
      )}
    </Box>
  );

  const customEventStyleGetter = (event, start, end, isSelected) => {
    let style = {
      backgroundColor: '#bdc3c7',
      color: '#333333',
      border: 'none',
      display: 'block'
    };

    if (event.status === 'pending') {
      style.backgroundColor = '#f39c12';
      style.color = '#ffffff';
    }

    if (event.status === 'approved') {
      style.backgroundColor = '#4a90e2';
      style.color = '#ffffff';
    }

    if (event.status === 'rejected') {
      style.backgroundColor = '#e74c3c';
      style.color = '#ffffff';
    }

    if (isSelected) {
      style.border = '2px solid #2c3e50';
    }

    if (isMobile) {
      style.fontSize = '0.75rem';
      style.padding = '2px';
    }

    return { style };
  };

  return (
    <BigCalendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      onSelectSlot={onSelectSlot}
      onSelectEvent={onSelectEvent}
      selectable
      eventPropGetter={customEventStyleGetter}
      dayPropGetter={dayPropGetter}
      components={{
        event: CustomEvent,
      }}
      popup
      views={isMobile ? ['month', 'agenda'] : ['month', 'week', 'day']}
      messages={{
        today: 'Vandaag',
        previous: 'Vorige',
        next: 'Volgende',
        month: 'Maand',
        week: 'Week',
        day: 'Dag',
        agenda: 'Agenda',
      }}
    />
  );
};

export default Calendar;
