import React from 'react';
import { Box, Typography } from '@mui/material';

const NotesDrawer = () => {
  return (
    <Box>
      <Typography variant="h6">Notities</Typography>
      {/* Implementeer hier de notities functionaliteit */}
    </Box>
  );
};

export default NotesDrawer;