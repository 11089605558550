import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, Switch } from "react-router-dom";
import { Box, CssBaseline, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { AppProvider, AppContext } from "./contexts/AppContext";
import { ShiftProvider } from "./contexts/ShiftContext";
import Sidebar from "./components/Sidebar";
import TopBar from "./components/TopBar";
import BottomNav from "./components/BottomNav";
import Login from "./pages/Login";
import AdminDashboard from "./pages/AdminDashboard";
import HotelDashboard from "./pages/HotelDashboard";
import UserDashboard from "./pages/UserDashboard";
import Agenda from "./components/Agenda";
import AccountManager from './components/accountManager/AccountManager';
import ProtectedRoute from "./components/ProtectedRoute";
import ShiftManager from "./components/ShiftManager";
import PersonnelManager from "./components/PersonnelManager";
import AdminSettings from "./pages/AdminSettings";
import ManageUsers from "./pages/ManageUsers";
import ManageHotels from "./pages/ManageHotelsPage";
import ShiftCalendar from "./components/ShiftCalendar";
import Settings from "./components/Settings";
import theme from './theme';
import './styles/global.css';
import HotelSettings from "./components/HotelSettings";
import HotelRatesManagement from './pages/HotelRatesManagement';

import { db } from './firebase';

import AdminCalendar from './pages/AdminCalendar';

function AppContent() {
  const { user, loading } = useContext(AppContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Box className="app-container" sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
        <CssBaseline />
        <TopBar />
        <Box sx={{ display: 'flex', flexGrow: 1, position: 'relative', overflow: 'hidden' }}>
          {user && !isMobile && <Sidebar />}
          <Box 
            component="main"
            className="content-container"
            sx={{
              flexGrow: 1,
              width: '100%',
              overflowY: 'auto',
              marginLeft: user && !isMobile ? '300px' : 0,
              marginTop: '64px',
              marginBottom: isMobile ? '56px' : 0,
              padding: isMobile ? '5px' : '20px',
              paddingBottom: isMobile ? '61px' : '20px',
              transition: 'margin 0.3s',
            }}
          >
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/admin" element={<ProtectedRoute adminOnly><AdminDashboard /></ProtectedRoute>} />
              <Route path="/admin/account-manager" element={<ProtectedRoute adminOnly><AccountManager /></ProtectedRoute>} />
              <Route path="/admin/manage-shifts" element={<ProtectedRoute adminOnly><ShiftManager /></ProtectedRoute>} />
              <Route path="/admin/personnel-manager" element={<ProtectedRoute adminOnly><PersonnelManager /></ProtectedRoute>} />
              <Route path="/admin/settings" element={<ProtectedRoute adminOnly><AdminSettings /></ProtectedRoute>} />
              <Route path="/admin/manage-users" element={<ProtectedRoute adminOnly><ManageUsers /></ProtectedRoute>} />
              <Route path="/admin/manage-hotels" element={<ProtectedRoute adminOnly><ManageHotels /></ProtectedRoute>} />
              <Route path="/admin/shift-calendar" element={<ProtectedRoute adminOnly><ShiftCalendar /></ProtectedRoute>} />
              <Route path="/admin/calendar" element={<ProtectedRoute adminOnly><AdminCalendar /></ProtectedRoute>} />
              <Route path="/hotel" element={<ProtectedRoute hotelOnly><HotelDashboard /></ProtectedRoute>} />
              <Route path="/hotel/agenda" element={<ProtectedRoute hotelOnly><Agenda /></ProtectedRoute>} />
              <Route path="/hotel/manage-users" element={<ProtectedRoute hotelOnly><ManageUsers /></ProtectedRoute>} />
              <Route path="/hotel/settings" element={<ProtectedRoute hotelOnly><HotelSettings /></ProtectedRoute>} />
              <Route path="/user/*" element={<ProtectedRoute><UserDashboard /></ProtectedRoute>} />
              <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
              <Route path="/" element={user ? <Navigate to={`/${user.role}`} /> : <Login />} />
              <Route path="/admin/hotel-rates" element={<ProtectedRoute adminOnly><HotelRatesManagement /></ProtectedRoute>} />
            </Routes>
          </Box>
        </Box>
        {user && isMobile && <BottomNav />}
      </Box>
    </Router>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <ShiftProvider>
          <AppContent />
        </ShiftProvider>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
