import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AppContext } from "../contexts/AppContext";

const ProtectedRoute = ({ children, adminOnly = false, hotelOnly = false }) => {
  const { user, loading } = useContext(AppContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (adminOnly && user.role !== "admin") {
    return <Navigate to="/" replace />;
  }

  if (hotelOnly && user.role !== "hotel") {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
