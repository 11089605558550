import React, { useState, useEffect, useContext } from 'react';
import { Typography, Box, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem } from '@mui/material';
import { collection, addDoc, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { db, auth } from '../firebase';
import { AppContext } from '../contexts/AppContext';

const ManageUsers = () => {
  const { user } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ name: '', email: '', password: '', role: 'user' });

  useEffect(() => {
    if (user && user.hotelId) {
      fetchUsers();
    }
  }, [user]);

  const fetchUsers = async () => {
    const q = query(collection(db, "users"), where("hotelId", "==", user.hotelId));
    const querySnapshot = await getDocs(q);
    setUsers(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleInputChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, newUser.email, newUser.password);
      const newUserId = userCredential.user.uid;

      await addDoc(collection(db, "users"), {
        id: newUserId,
        name: newUser.name,
        email: newUser.email,
        role: newUser.role,
        hotelId: user.hotelId
      });

      setNewUser({ name: '', email: '', password: '', role: 'user' });
      fetchUsers();
    } catch (error) {
      console.error("Error adding new user:", error);
    }
  };

  const handleRoleChange = async (userId, newRole) => {
    try {
      await updateDoc(doc(db, "users", userId), { role: newRole });
      fetchUsers();
    } catch (error) {
      console.error("Error updating user role:", error);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Manage Users
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mb: 4 }}>
        <TextField
          name="name"
          label="Name"
          value={newUser.name}
          onChange={handleInputChange}
          required
          sx={{ mr: 2 }}
        />
        <TextField
          name="email"
          label="Email"
          type="email"
          value={newUser.email}
          onChange={handleInputChange}
          required
          sx={{ mr: 2 }}
        />
        <TextField
          name="password"
          label="Password"
          type="password"
          value={newUser.password}
          onChange={handleInputChange}
          required
          sx={{ mr: 2 }}
        />
        <Select
          name="role"
          value={newUser.role}
          onChange={handleInputChange}
          sx={{ mr: 2 }}
        >
          <MenuItem value="user">User</MenuItem>
          <MenuItem value="manager">Manager</MenuItem>
        </Select>
        <Button type="submit" variant="contained">Add User</Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>
                  <Select
                    value={user.role}
                    onChange={(e) => handleRoleChange(user.id, e.target.value)}
                  >
                    <MenuItem value="user">User</MenuItem>
                    <MenuItem value="manager">Manager</MenuItem>
                  </Select>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ManageUsers;
