import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, IconButton, Button, List, ListItem, useMediaQuery, Paper } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import moment from 'moment';
import 'moment/locale/nl';

moment.locale('nl');

const MobileCalendar = ({ events, onSelectDate, selectedDates, onRequestShifts }) => {
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [selectedShifts, setSelectedShifts] = useState([]);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handlePrevMonth = () => setCurrentMonth(prev => prev.clone().subtract(1, 'month'));
  const handleNextMonth = () => setCurrentMonth(prev => prev.clone().add(1, 'month'));

  useEffect(() => {
    // Hier zou u normaal gesproken een API-aanroep doen om de diensten op te halen
    // Voor nu simuleren we dit met dummy data
    const dummyShifts = selectedDates.map(date => ({
      date,
      name: `Dienst op ${date}`,
      hours: Math.floor(Math.random() * 8) + 1
    }));
    setSelectedShifts(dummyShifts);
  }, [selectedDates]);

  const daysInMonth = currentMonth.daysInMonth();
  const firstDayOfMonth = currentMonth.clone().startOf('month').day();

  const renderCalendarDays = () => {
    const days = [];
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<Box key={`empty-${i}`} />);
    }
    for (let day = 1; day <= daysInMonth; day++) {
      const date = currentMonth.clone().date(day);
      const dateString = date.format('YYYY-MM-DD');
      const isSelected = selectedDates.includes(dateString);
      const hasEvent = events.some(event => moment(event.start).isSame(date, 'day'));

      days.push(
        <Box
          key={day}
          onClick={() => onSelectDate(date.toDate())}
          sx={{
            width: 40,
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            borderRadius: '50%',
            backgroundColor: isSelected ? 'primary.main' : hasEvent ? 'secondary.light' : 'transparent',
            color: isSelected ? 'white' : hasEvent ? 'secondary.dark' : 'inherit',
            '&:hover': {
              backgroundColor: isSelected ? 'primary.dark' : 'action.hover',
            },
          }}
        >
          {day}
        </Box>
      );
    }
    return days;
  };

  const totalHours = selectedShifts.reduce((sum, shift) => sum + shift.hours, 0);

  return (
    <Box sx={{ 
      p: { xs: 0.5, sm: 1 }, // Nog minder padding
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column',
      maxWidth: '100%',
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 2,
        px: { xs: 0.5, sm: 1 } // Minder horizontale padding
      }}>
        <IconButton onClick={handlePrevMonth}>
          <ChevronLeft />
        </IconButton>
        <Typography variant="h6">
          {currentMonth.format('MMMM YYYY')}
        </Typography>
        <IconButton onClick={handleNextMonth}>
          <ChevronRight />
        </IconButton>
      </Box>
      <Grid container spacing={1} columns={7} sx={{ mx: { xs: -0.5, sm: 0 } }}> {/* Aangepaste negatieve marge */}
        {['Z', 'M', 'D', 'W', 'D', 'V', 'Z'].map(day => (
          <Grid item xs={1} key={day}>
            <Typography align="center" variant="body2">{day}</Typography>
          </Grid>
        ))}
        {renderCalendarDays().map((day, index) => (
          <Grid item xs={1} key={index}>
            {day}
          </Grid>
        ))}
      </Grid>
      
      {selectedDates.length > 0 && (
        <Paper sx={{ 
          mt: 2, 
          flexGrow: 1, 
          overflow: 'auto', 
          maxHeight: 'calc(100vh - 400px)',
          mx: { xs: -0.5, sm: 0 }, // Aangepaste negatieve marge
          p: 2 // Padding binnen het Paper element
        }}>
          <Typography variant="h6" sx={{ mb: 1 }}>Geselecteerde diensten:</Typography>
          <List sx={{ p: 0 }}> {/* Geen extra padding voor de lijst */}
            {selectedShifts.map((shift, index) => (
              <ListItem key={index} sx={{ px: 1, py: 0.5 }}> {/* Meer ruimte voor elke shift */}
                {shift.name} - {shift.startTime} tot {shift.endTime}
              </ListItem>
            ))}
          </List>
          <Typography sx={{ mt: 1 }}>Totaal aantal diensten: {selectedShifts.length}</Typography>
        </Paper>
      )}
      
      {selectedDates.length > 0 && (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button 
            variant="contained" 
            color="primary"
            onClick={() => onRequestShifts(selectedDates)}
            fullWidth
            sx={{ mx: { xs: 0.5, sm: 1 } }} // Iets meer ruimte aan de zijkanten van de knop
          >
            Diensten opvragen ({selectedDates.length})
          </Button>
        </Box>
      )}
      
      {!isMobile && selectedDates.length > 0 && (
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Button 
            variant="contained" 
            color="secondary"
          >
            Desktop knop ({selectedDates.length})
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default MobileCalendar;
