import { collection, query, where, getDocs, Timestamp, orderBy, limit } from "firebase/firestore";
import { db } from '../firebase';

export const fetchShiftStats = async (hotelId) => {
  const shiftsRef = collection(db, "diensten");
  const now = new Date();
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  
  const q = query(
    shiftsRef,
    where("hotelId", "==", hotelId),
    where("start", ">=", firstDayOfMonth)
  );

  const querySnapshot = await getDocs(q);
  const stats = {
    approvedShiftsThisMonth: 0,
    rejectedShifts: 0,
    totalShiftsRequested: querySnapshot.size,
    shiftsRequestedThisMonth: querySnapshot.size,
    pendingShifts: 0,
  };

  querySnapshot.forEach((doc) => {
    const shift = doc.data();
    if (shift.status === 'approved') {
      stats.approvedShiftsThisMonth++;
    } else if (shift.status === 'rejected') {
      stats.rejectedShifts++;
    } else if (shift.status === 'pending') {
      stats.pendingShifts++;
    }
  });

  return stats;
};

export const fetchMonthlyData = async (hotelId) => {
  const shiftsRef = collection(db, "diensten");
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

  const q = query(
    shiftsRef,
    where("hotelId", "==", hotelId),
    where("start", ">=", sixMonthsAgo),
    orderBy("start", "asc")
  );

  const querySnapshot = await getDocs(q);
  const monthlyData = {};

  querySnapshot.forEach((doc) => {
    const shift = doc.data();
    const month = new Date(shift.start.seconds * 1000).toLocaleString('default', { month: 'short' });
    
    if (!monthlyData[month]) {
      monthlyData[month] = { shifts: 0, revenue: 0 };
    }
    
    monthlyData[month].shifts++;
    if (shift.status === 'approved') {
      monthlyData[month].revenue += shift.rate || 0;
    }
  });

  return Object.entries(monthlyData).map(([name, data]) => ({ name, ...data }));
};

export const fetchRecentActivity = async (hotelId) => {
  const shiftsRef = collection(db, "diensten");
  const q = query(
    shiftsRef,
    where("hotelId", "==", hotelId),
    orderBy("createdAt", "desc"),
    limit(5)
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    createdAt: doc.data().createdAt.toDate()
  }));
};

export const fetchShiftNotes = async (hotelId) => {
  const notesRef = collection(db, "shift_notes");
  const q = query(
    notesRef,
    where("hotelId", "==", hotelId),
    orderBy("createdAt", "desc"),
    limit(5)
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    createdAt: doc.data().createdAt.toDate()
  }));
};

export const fetchUpcomingShifts = async (hotelId) => {
  const now = new Date();
  const q = query(
    collection(db, "diensten"),
    where("hotelId", "==", hotelId),
    where("start", ">=", now),
    orderBy("start", "asc"),
    limit(5)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    start: doc.data().start.toDate(),
    end: doc.data().end.toDate()
  }));
};

export const fetchNotifications = async (hotelId) => {
  const notificationsRef = collection(db, "notifications");
  const q = query(
    notificationsRef,
    where("hotelId", "==", hotelId),
    orderBy("createdAt", "desc"),
    limit(5)
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
    createdAt: doc.data().createdAt.toDate()
  }));
};
