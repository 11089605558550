import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: 'hsl(0, 0%, 100%)',
      paper: 'hsl(0, 0%, 100%)',
    },
    primary: {
      main: 'hsl(24.6, 95%, 53.1%)', // Oranje kleur
      contrastText: 'hsl(60, 9.1%, 97.8%)',
    },
    secondary: {
      main: 'hsl(60, 4.8%, 95.9%)',
      contrastText: 'hsl(24, 9.8%, 10%)',
    },
    text: {
      primary: 'hsl(20, 14.3%, 4.1%)',
      secondary: 'hsl(25, 5.3%, 44.7%)',
    },
    error: {
      main: 'hsl(0, 84.2%, 60.2%)',
    },
  },
  shape: {
    borderRadius: 16,
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          backgroundColor: 'hsl(24.6, 95%, 53.1%)', // Oranje kleur
          color: 'hsl(60, 9.1%, 97.8%)', // Contrasterende tekstkleur
          '&:hover': {
            backgroundColor: 'hsl(24.6, 95%, 43.1%)', // Donkerdere oranje kleur voor hover
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'hsl(0, 0%, 100%)',
          borderRadius: '1rem',
        },
      },
    },
  },
});

export default theme;
