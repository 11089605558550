import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, TextField, Select, MenuItem, Autocomplete, Chip, Avatar, Button, Checkbox, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, CircularProgress, IconButton, Menu } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { collection, query, getDocs, where, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const formatDateAndTime = (start, end) => {
  if (!start || !end) return { date: 'Ongeldige datum', startTime: 'Ongeldige tijd', endTime: 'Ongeldige tijd' };
  const startDate = start.seconds ? new Date(start.seconds * 1000) : new Date(start);
  const endDate = end.seconds ? new Date(end.seconds * 1000) : new Date(end);
  return {
    date: startDate.toLocaleDateString(),
    startTime: startDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    endTime: endDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  };
};

const ShiftManager = () => {
  const initialFilters = {
    startDate: null,
    endDate: null,
    hotels: [],
    locations: [],
    serviceTypes: [],
    minRate: '',
    status: 'pending',
    type: 'all',
    search: '',
    personnel: [],
  };

  const [shifts, setShifts] = useState([]);
  const [filteredShifts, setFilteredShifts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [sortConfig, setSortConfig] = useState({ key: 'start', direction: 'asc' });
  const [selectedShifts, setSelectedShifts] = useState([]);
  const [hotelData, setHotelData] = useState([]);
  const [personnelData, setPersonnelData] = useState([]);
  const [allServiceTypes, setAllServiceTypes] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedShiftId, setSelectedShiftId] = useState(null);

  useEffect(() => {
    fetchShifts();
    fetchHotelData();
    fetchPersonnelData();
    fetchAllServiceTypes();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [shifts, filters]);

  const fetchShifts = async () => {
    try {
      setLoading(true);
      const shiftsQuery = query(collection(db, 'diensten'));
      const shiftsSnapshot = await getDocs(shiftsQuery);
      const shiftsData = shiftsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setShifts(shiftsData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching shifts:', error);
      setLoading(false);
    }
  };

  const fetchHotelData = async () => {
    try {
      const hotelsQuery = query(collection(db, 'users'), where('role', '==', 'hotel'));
      const hotelsSnapshot = await getDocs(hotelsQuery);
      const hotelsData = hotelsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setHotelData(hotelsData);
    } catch (error) {
      console.error('Fout bij het ophalen van hotelgegevens:', error);
    }
  };

  const fetchPersonnelData = async () => {
    try {
      const personnelQuery = query(collection(db, 'personnel'));
      const personnelSnapshot = await getDocs(personnelQuery);
      const personnelData = personnelSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPersonnelData(personnelData);
    } catch (error) {
      console.error('Fout bij het ophalen van personeelsgegevens:', error);
    }
  };

  const fetchAllServiceTypes = async () => {
    try {
      const hotelsQuery = query(collection(db, 'users'), where('role', '==', 'hotel'));
      const hotelsSnapshot = await getDocs(hotelsQuery);
      const serviceTypesSet = new Set();
      hotelsSnapshot.forEach(doc => {
        const hotelData = doc.data();
        if (hotelData.serviceTypes && Array.isArray(hotelData.serviceTypes)) {
          hotelData.serviceTypes.forEach(type => serviceTypesSet.add(type));
        }
      });
      setAllServiceTypes(Array.from(serviceTypesSet));
    } catch (error) {
      console.error('Fout bij het ophalen van service types:', error);
    }
  };

  const handleFilterChange = (filterName, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [filterName]: value }));
  };

  const applyFilters = () => {
    let filteredResults = shifts;

    if (filters.startDate) {
      filteredResults = filteredResults.filter(shift => new Date(shift.start) >= filters.startDate);
    }

    if (filters.endDate) {
      filteredResults = filteredResults.filter(shift => new Date(shift.start) <= filters.endDate);
    }

    if (filters.hotels.length > 0) {
      filteredResults = filteredResults.filter(shift => filters.hotels.includes(shift.hotelId));
    }

    if (filters.locations.length > 0) {
      filteredResults = filteredResults.filter(shift => {
        const hotel = hotelData.find(h => h.id === shift.hotelId);
        return hotel && filters.locations.includes(hotel.location);
      });
    }

    if (filters.serviceTypes.length > 0) {
      filteredResults = filteredResults.filter(shift => filters.serviceTypes.includes(shift.type));
    }

    if (filters.minRate) {
      filteredResults = filteredResults.filter(shift => {
        const hotel = hotelData.find(h => h.id === shift.hotelId);
        return hotel && parseFloat(hotel.rate) >= parseFloat(filters.minRate);
      });
    }

    if (filters.status !== 'all') {
      filteredResults = filteredResults.filter(shift => shift.status === filters.status);
    }

    if (filters.type !== 'all') {
      filteredResults = filteredResults.filter(shift => shift.type === filters.type);
    }

    if (filters.personnel.length > 0) {
      filteredResults = filteredResults.filter(shift => {
        // Hier moet je de logica implementeren om te controleren of het personeel is toegewezen aan de shift
        // Dit hangt af van hoe je de relatie tussen shifts en personeel hebt opgezet in je database
        return true; // Placeholder
      });
    }

    if (filters.search) {
      const searchLower = filters.search.toLowerCase();
      filteredResults = filteredResults.filter(shift =>
        shift.hotelName.toLowerCase().includes(searchLower) ||
        shift.type.toLowerCase().includes(searchLower)
      );
    }

    setFilteredShifts(filteredResults);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedShifts = [...filteredShifts].sort((a, b) => {
      if (key === 'start') {
        const dateA = a[key] ? new Date(a[key].seconds * 1000) : new Date(0);
        const dateB = b[key] ? new Date(b[key].seconds * 1000) : new Date(0);
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      }
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setFilteredShifts(sortedShifts);
  };

  const handleShiftSelection = (shiftId) => {
    setSelectedShifts(prevSelected =>
      prevSelected.includes(shiftId)
        ? prevSelected.filter(id => id !== shiftId)
        : [...prevSelected, shiftId]
    );
  };

  const handleBulkAction = async (action) => {
    try {
      for (const shiftId of selectedShifts) {
        const shiftRef = doc(db, 'diensten', shiftId);
        switch (action) {
          case 'approve':
            await updateDoc(shiftRef, { status: 'approved' });
            break;
          case 'reject':
            await updateDoc(shiftRef, { status: 'rejected' });
            break;
          case 'delete':
            await deleteDoc(shiftRef);
            break;
          default:
            console.error('Ongeldige actie');
        }
      }
      // Ververs de shifts na de bulkactie
      fetchShifts();
      setSelectedShifts([]);
    } catch (error) {
      console.error(`Fout bij het uitvoeren van bulkactie ${action}:`, error);
    }
  };

  const handleActionClick = (event, shiftId) => {
    setAnchorEl(event.currentTarget);
    setSelectedShiftId(shiftId);
  };

  const handleActionClose = () => {
    setAnchorEl(null);
    setSelectedShiftId(null);
  };

  const handleShiftAction = async (action) => {
    if (selectedShiftId) {
      try {
        const shiftRef = doc(db, 'diensten', selectedShiftId);
        if (action === 'delete') {
          await deleteDoc(shiftRef);
        } else {
          await updateDoc(shiftRef, { status: action });
        }
        fetchShifts();
      } catch (error) {
        console.error('Error updating shift:', error);
      }
    }
    handleActionClose();
  };

  const handleResetFilters = () => {
    setFilters(initialFilters);
  };

  const handleApproveSelected = async () => {
    try {
      for (const shiftId of selectedShifts) {
        const shiftRef = doc(db, 'diensten', shiftId);
        await updateDoc(shiftRef, { status: 'approved' });
      }
      // Ververs de shifts na goedkeuring
      fetchShifts();
      setSelectedShifts([]);
    } catch (error) {
      console.error('Fout bij het goedkeuren van diensten:', error);
    }
  };

  const handleAssignPersonnel = async (shiftId, personnelId) => {
    try {
      const shiftRef = doc(db, 'diensten', shiftId);
      await updateDoc(shiftRef, { assignedPersonnelId: personnelId });
      // Ververs de shifts na toewijzing
      fetchShifts();
    } catch (error) {
      console.error('Fout bij het toewijzen van personeel:', error);
    }
  };

  const ShiftStatistics = ({ shifts }) => {
    const totalShifts = shifts.length;
    const pendingShifts = shifts.filter(shift => shift.status === 'pending').length;
    const approvedShifts = shifts.filter(shift => shift.status === 'approved').length;
    const rejectedShifts = shifts.filter(shift => shift.status === 'rejected').length;

    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography>Totaal aantal diensten: {totalShifts}</Typography>
        <Typography>Pending: {pendingShifts}</Typography>
        <Typography>Goedgekeurd: {approvedShifts}</Typography>
        <Typography>Afgewezen: {rejectedShifts}</Typography>
      </Box>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>Filters</Typography>
              
              <Select
                fullWidth
                value={filters.status}
                onChange={(e) => handleFilterChange('status', e.target.value)}
                margin="normal"
              >
                <MenuItem value="all">Alle statussen</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="approved">Goedgekeurd</MenuItem>
                <MenuItem value="rejected">Afgewezen</MenuItem>
              </Select>

              <Box sx={{ my: 2 }} />

              <DatePicker
                label="Start datum"
                value={filters.startDate}
                onChange={(newValue) => handleFilterChange('startDate', newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                inputFormat="dd/MM/yyyy"
                sx={{ width: '100%' }}
              />
              <DatePicker
                label="Eind datum"
                value={filters.endDate}
                onChange={(newValue) => handleFilterChange('endDate', newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                inputFormat="dd/MM/yyyy"
                sx={{ width: '100%' }}
              />

              <Autocomplete
                multiple
                options={hotelData}
                getOptionLabel={(option) => option.name}
                value={filters.hotels}
                onChange={(event, newValue) => handleFilterChange('hotels', newValue)}
                renderInput={(params) => <TextField {...params} label="Hotels" fullWidth margin="normal" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.name}
                      {...getTagProps({ index })}
                      avatar={<Avatar alt={option.name} src={option.companyLogoUrl} />}
                    />
                  ))
                }
              />

              <Autocomplete
                multiple
                options={Array.from(new Set(hotelData.map(hotel => hotel.location)))}
                value={filters.locations}
                onChange={(event, newValue) => handleFilterChange('locations', newValue)}
                renderInput={(params) => <TextField {...params} label="Locaties" fullWidth margin="normal" />}
              />

              <Autocomplete
                multiple
                options={allServiceTypes}
                value={filters.serviceTypes}
                onChange={(event, newValue) => handleFilterChange('serviceTypes', newValue)}
                renderInput={(params) => <TextField {...params} label="Service Types" fullWidth margin="normal" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />

              <TextField
                fullWidth
                label="Minimum tarief"
                type="number"
                value={filters.minRate}
                onChange={(e) => handleFilterChange('minRate', e.target.value)}
                margin="normal"
              />

              <Autocomplete
                multiple
                options={personnelData}
                getOptionLabel={(option) => option.name}
                value={filters.personnel}
                onChange={(event, newValue) => handleFilterChange('personnel', newValue)}
                renderInput={(params) => <TextField {...params} label="Personeel" fullWidth margin="normal" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.name}
                      {...getTagProps({ index })}
                      avatar={<Avatar alt={option.name} src={option.photoURL} />}
                    />
                  ))
                }
              />

              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="outlined" onClick={handleResetFilters}>
                  Reset Filters
                </Button>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            <Paper sx={{ p: 2, mb: 2 }}>
              <TextField
                fullWidth
                label="Zoeken"
                value={filters.search}
                onChange={(e) => handleFilterChange('search', e.target.value)}
                margin="normal"
              />
            </Paper>
            
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-start' }}>
              <Button 
                variant="contained"
                color="primary" 
                onClick={() => handleBulkAction('approve')}
                disabled={selectedShifts.length === 0}
                sx={{ mr: 2 }}
              >
                Goedkeuren
              </Button>
              <Button 
                variant="contained"
                color="secondary" 
                onClick={() => handleBulkAction('reject')}
                disabled={selectedShifts.length === 0}
                sx={{ mr: 2 }}
              >
                Afkeuren
              </Button>
              <Button 
                variant="contained"
                color="error" 
                onClick={() => handleBulkAction('delete')}
                disabled={selectedShifts.length === 0}
              >
                Verwijderen
              </Button>
            </Box>

            <Paper sx={{ p: 2 }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={selectedShifts.length > 0 && selectedShifts.length < filteredShifts.length}
                          checked={selectedShifts.length === filteredShifts.length}
                          onChange={() => {
                            if (selectedShifts.length === filteredShifts.length) {
                              setSelectedShifts([]);
                            } else {
                              setSelectedShifts(filteredShifts.map(shift => shift.id));
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.key === 'hotelName'}
                          direction={sortConfig.key === 'hotelName' ? sortConfig.direction : 'asc'}
                          onClick={() => handleSort('hotelName')}
                        >
                          Hotel
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.key === 'start'}
                          direction={sortConfig.key === 'start' ? sortConfig.direction : 'asc'}
                          onClick={() => handleSort('start')}
                        >
                          Datum
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.key === 'start'}
                          direction={sortConfig.key === 'start' ? sortConfig.direction : 'asc'}
                          onClick={() => handleSort('start')}
                        >
                          Tijd
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.key === 'end'}
                          direction={sortConfig.key === 'end' ? sortConfig.direction : 'asc'}
                          onClick={() => handleSort('end')}
                        >
                          Eindtijd
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.key === 'type'}
                          direction={sortConfig.key === 'type' ? sortConfig.direction : 'asc'}
                          onClick={() => handleSort('type')}
                        >
                          Type
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortConfig.key === 'status'}
                          direction={sortConfig.key === 'status' ? sortConfig.direction : 'asc'}
                          onClick={() => handleSort('status')}
                        >
                          Status
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>Personeel</TableCell>
                      <TableCell>Acties</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : filteredShifts.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          Geen diensten gevonden
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredShifts.map((shift) => (
                        <TableRow key={shift.id}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedShifts.includes(shift.id)}
                              onChange={() => handleShiftSelection(shift.id)}
                            />
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {hotelData.find(h => h.id === shift.hotelId)?.companyLogoUrl ? (
                                <Avatar sx={{ width: 24, height: 24, mr: 1 }} src={hotelData.find(h => h.id === shift.hotelId)?.companyLogoUrl} />
                              ) : (
                                <Avatar sx={{ width: 24, height: 24, mr: 1, bgcolor: shift.hotelColor || '#000000' }}>
                                  {shift.hotelName ? shift.hotelName[0].toUpperCase() : 'H'}
                                </Avatar>
                              )}
                              {shift.hotelName}
                            </Box>
                          </TableCell>
                          <TableCell>{formatDateAndTime(shift.start, shift.end).date}</TableCell>
                          <TableCell>{formatDateAndTime(shift.start, shift.end).startTime}</TableCell>
                          <TableCell>{formatDateAndTime(shift.start, shift.end).endTime}</TableCell>
                          <TableCell>{shift.type}</TableCell>
                          <TableCell>{shift.status}</TableCell>
                          <TableCell>
                            <Autocomplete
                              options={personnelData}
                              getOptionLabel={(option) => option.name}
                              value={personnelData.find(p => p.id === shift.assignedPersonnelId) || null}
                              onChange={(event, newValue) => {
                                handleAssignPersonnel(shift.id, newValue ? newValue.id : null);
                              }}
                              renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
                              renderOption={(props, option) => (
                                <li {...props}>
                                  <Avatar src={option.photoURL} sx={{ width: 24, height: 24, mr: 1 }} />
                                  <Typography variant="body2">{option.name}</Typography>
                                </li>
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton onClick={(event) => handleActionClick(event, shift.id)}>
                              <MoreVertIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleActionClose}
      >
        <MenuItem onClick={() => handleShiftAction('approved')}>Goedkeuren</MenuItem>
        <MenuItem onClick={() => handleShiftAction('rejected')}>Afkeuren</MenuItem>
        <MenuItem onClick={() => handleShiftAction('delete')}>Verwijderen</MenuItem>
      </Menu>
    </LocalizationProvider>
  );
};

export default ShiftManager;
