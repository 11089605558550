import React from 'react';
import { Typography, Box, Chip, Button, Paper } from "@mui/material";

const PendingShifts = ({ pendingShifts, onViewAll }) => {
  const gradientBackground = 'linear-gradient(45deg, #FFE0E6 30%, #FFD1DC 90%)';

  return (
    <Paper 
      elevation={2}
      sx={{ 
        p: 2, 
        borderRadius: 2,
        background: gradientBackground
      }}
    >
      <Typography variant="h6" gutterBottom>Openstaande Diensten</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Chip
            label={pendingShifts}
            color="warning"
            sx={{ mr: 2, fontWeight: 'bold' }}
          />
          <Typography variant="body1">
            Openstaande diensten
          </Typography>
        </Box>
        <Button 
          variant="contained" 
          onClick={onViewAll}
          sx={{ 
            backgroundColor: 'white', 
            color: 'text.primary',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
            }
          }}
        >
          Bekijk Alles
        </Button>
      </Box>
    </Paper>
  );
};

export default PendingShifts;
