import React from 'react';
import { Card, CardContent, CardActions, Typography, Button, Avatar, Box } from '@mui/material';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';

const AccountCard = ({ account, setEditingAccount, setOpenDialog, fetchAccounts, setSnackbar }) => {
  const handleDeleteAccount = async () => {
    try {
      await deleteDoc(doc(db, "users", account.id));
      fetchAccounts();
      setSnackbar({ open: true, message: 'Account succesvol verwijderd', severity: 'success' });
    } catch (error) {
      console.error("Fout bij het verwijderen van account:", error);
      setSnackbar({ open: true, message: 'Fout bij het verwijderen van account', severity: 'error' });
    }
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Avatar src={account.profilePictureUrl} sx={{ mr: 2 }} />
          <Typography variant="h6">{account.name || account.email}</Typography>
        </Box>
        <Typography color="textSecondary">Rol: {account.role}</Typography>
        {account.rate && <Typography>Tarief: €{account.rate}/uur</Typography>}
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => { setEditingAccount(account); setOpenDialog(true); }}>Bewerken</Button>
        <Button size="small" color="error" onClick={handleDeleteAccount}>Verwijderen</Button>
      </CardActions>
    </Card>
  );
};

export default AccountCard;
