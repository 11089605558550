import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia, CardActionArea, Button, Dialog, DialogTitle, DialogContent, Snackbar, Alert } from '@mui/material';
import { collection, query, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import HotelCreationForm from './HotelCreationForm';
import EditHotelForm from './EditHotelForm';

const ManageHotels = () => {
  const [hotels, setHotels] = useState([]);
  const [openCreateForm, setOpenCreateForm] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchHotels();
  }, []);

  const fetchHotels = async () => {
    try {
      const hotelsQuery = query(collection(db, 'users'));
      const hotelsSnapshot = await getDocs(hotelsQuery);
      const hotelsData = hotelsSnapshot.docs
        .filter(doc => doc.data().role === 'hotel')
        .map(doc => ({ id: doc.id, ...doc.data() }));
      setHotels(hotelsData);
    } catch (error) {
      console.error('Error fetching hotels:', error);
      setSnackbar({ open: true, message: 'Error fetching hotels', severity: 'error' });
    }
  };

  const handleOpenCreateForm = () => setOpenCreateForm(true);
  const handleCloseCreateForm = () => {
    setOpenCreateForm(false);
    fetchHotels();
  };

  const handleOpenEditForm = (hotel) => {
    setSelectedHotel(hotel);
    setOpenEditForm(true);
  };

  const handleCloseEditForm = () => {
    setSelectedHotel(null);
    setOpenEditForm(false);
  };

  const handleUpdateHotel = async (updatedHotel) => {
    try {
      await updateDoc(doc(db, 'users', updatedHotel.id), updatedHotel);
      setSnackbar({ open: true, message: 'Hotel updated successfully', severity: 'success' });
      handleCloseEditForm();
      fetchHotels();
    } catch (error) {
      console.error('Error updating hotel:', error);
      setSnackbar({ open: true, message: 'Error updating hotel', severity: 'error' });
    }
  };

  const handleDeleteHotel = async (hotelId) => {
    try {
      await deleteDoc(doc(db, 'users', hotelId));
      setSnackbar({ open: true, message: 'Hotel deleted successfully', severity: 'success' });
      handleCloseEditForm();
      fetchHotels();
    } catch (error) {
      console.error('Error deleting hotel:', error);
      setSnackbar({ open: true, message: 'Error deleting hotel', severity: 'error' });
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Manage Hotels
      </Typography>
      <Button variant="contained" onClick={handleOpenCreateForm} sx={{ mb: 3 }}>
        Add New Hotel
      </Button>
      <Grid container spacing={3}>
        {hotels.map((hotel) => (
          <Grid item xs={12} sm={6} md={4} key={hotel.id}>
            <Card>
              <CardActionArea onClick={() => handleOpenEditForm(hotel)}>
                <CardMedia
                  component="img"
                  height="140"
                  image={hotel.imageUrl || 'https://source.unsplash.com/random/300x140/?hotel'}
                  alt={hotel.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {hotel.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Email: {hotel.email}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Address: {hotel.location || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Rate: €{hotel.rate || 'N/A'}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog open={openCreateForm} onClose={handleCloseCreateForm} maxWidth="md" fullWidth>
        <DialogTitle>Create New Hotel</DialogTitle>
        <DialogContent>
          <HotelCreationForm onClose={handleCloseCreateForm} />
        </DialogContent>
      </Dialog>
      <Dialog open={openEditForm} onClose={handleCloseEditForm} maxWidth="md" fullWidth>
        <DialogTitle>Edit Hotel</DialogTitle>
        <DialogContent>
          {selectedHotel && (
            <EditHotelForm
              hotel={selectedHotel}
              onUpdate={handleUpdateHotel}
              onCancel={handleCloseEditForm}
              onDelete={handleDeleteHotel}
            />
          )}
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ManageHotels;
