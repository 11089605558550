import React, { useEffect, useState, useContext } from 'react';
import { Typography, List, ListItem, ListItemText, Box, Button } from '@mui/material';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import moment from 'moment';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { AppContext } from '../../contexts/AppContext';

const ShiftNotes = ({ onViewAllClick }) => {
  const [notes, setNotes] = useState([]);
  const { user } = useContext(AppContext);

  useEffect(() => {
    const fetchNotes = async () => {
      if (user && user.hotelId) {
        const q = query(
          collection(db, 'diensten'),
          where('hotelId', '==', user.hotelId),
          where('notes', '!=', ''),
          orderBy('notes'),
          limit(2)
        );

        const querySnapshot = await getDocs(q);
        const fetchedNotes = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          date: doc.data().start.toDate()
        }));
        setNotes(fetchedNotes);
      }
    };

    fetchNotes();
  }, [user]);

  return (
    <>
      <List>
        {notes.map((note) => (
          <ListItem 
            key={note.id} 
            disablePadding 
            sx={{ 
              mb: 1, 
              borderRadius: '8px', 
              background: 'linear-gradient(135deg, #e6f2ff 0%, #b3d9ff 100%)',
              overflow: 'hidden'
            }}
          >
            <Box sx={{ p: 1.5, width: '100%', display: 'flex', alignItems: 'center' }}>
              <Box 
                sx={{ 
                  mr: 2, 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  width: 40, 
                  height: 40, 
                  borderRadius: '50%', 
                  bgcolor: 'rgba(255, 152, 0, 0.1)',
                  color: '#ff9800'
                }}
              >
                <AssignmentOutlinedIcon />
              </Box>
              <ListItemText
                primary={
                  <Typography variant="subtitle2" color="primary">
                    {moment(note.date).format('D MMM YYYY')} - {note.type}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                    {note.notes}
                  </Typography>
                }
              />
            </Box>
          </ListItem>
        ))}
      </List>
      <Button 
        variant="outlined" 
        color="primary" 
        fullWidth 
        onClick={onViewAllClick}
        sx={{ 
          borderRadius: '8px', 
          textTransform: 'none', 
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: 'rgba(25, 118, 210, 0.04)'
          }
        }}
      >
        Alle notities
      </Button>
    </>
  );
};

export default ShiftNotes;
