import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyATlIK7MW6q1_1ENRmDWT06IYOVKdMwvM8",
  authDomain: "flexx-crm-app.firebaseapp.com",
  projectId: "flexx-crm-app",
  storageBucket: "flexx-crm-app.appspot.com",
  messagingSenderId: "897670406598",
  appId: "1:897670406598:web:194be2338e2421d65ed2c7",
  measurementId: "G-YL9X3RG611"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, analytics, auth, db, storage };
