import React from 'react';
import { Typography, List, ListItem, ListItemText, IconButton, Box, Paper, Button, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Delete as DeleteIcon, Info as InfoIcon } from '@mui/icons-material';
import moment from 'moment';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(3),
  maxHeight: 'calc(100vh - 200px)',
  overflow: 'hidden',
}));

const StyledList = styled(List)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  marginBottom: theme.spacing(2),
  '&::-webkit-scrollbar': {
    width: '6px !important',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1 !important',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#c1c1c1 !important',
    borderRadius: '3px !important',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#a8a8a8 !important',
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: '#e0e0e0',
  marginBottom: theme.spacing(1),
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#d0d0d0',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  padding: theme.spacing(1, 3),
}));

const ShiftSidebar = ({ newEvents, onRemoveEvent, onCreateEvents }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Box sx={{ position: 'fixed', bottom: 80, right: 20, zIndex: 1000 }}>
        <StyledButton
          onClick={onCreateEvents}
          color="primary"
          variant="contained"
          startIcon={<InfoIcon />}
        >
          {newEvents.length} Diensten
        </StyledButton>
      </Box>
    );
  }

  return (
    <StyledPaper>
      <Typography variant="h6" gutterBottom>Geselecteerde Diensten</Typography>
      <StyledList>
        {newEvents.map((event, index) => (
          <StyledListItem key={index}>
            <ListItemText
              primary={`${event.type}`}
              secondary={`${moment(event.date).format('DD/MM/YYYY')} ${event.startTime} - ${event.endTime}`}
            />
            <Box>
              <IconButton edge="end" aria-label="delete" onClick={() => onRemoveEvent(event.tempId)}>
                <DeleteIcon />
              </IconButton>
              {event.notes && (
                <Tooltip title={event.notes}>
                  <IconButton aria-label="info">
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </StyledListItem>
        ))}
      </StyledList>
      <Box sx={{ padding: 2 }}>
        <StyledButton 
          onClick={onCreateEvents} 
          color="primary" 
          variant="contained"
          fullWidth
        >
          Diensten Aanvragen
        </StyledButton>
      </Box>
    </StyledPaper>
  );
};

export default ShiftSidebar;
