import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import moment from 'moment';

const DienstenLijst = ({ shifts }) => {
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader aria-label="diensten tabel">
        <TableHead>
          <TableRow>
            <TableCell>Hotel</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Datum</TableCell>
            <TableCell>Starttijd</TableCell>
            <TableCell>Eindtijd</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shifts.map((shift) => (
            <TableRow key={shift.id}>
              <TableCell>{shift.hotelName}</TableCell>
              <TableCell>{shift.type}</TableCell>
              <TableCell>{moment(shift.start.toDate()).format('DD-MM-YYYY')}</TableCell>
              <TableCell>{moment(shift.start.toDate()).format('HH:mm')}</TableCell>
              <TableCell>{moment(shift.end.toDate()).format('HH:mm')}</TableCell>
              <TableCell>{shift.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DienstenLijst;
