import React from 'react';
import { Box, IconButton, Typography, Grid } from '@mui/material';
import { Assignment, EventNote, Assignment as RosterIcon, CalendarToday } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const QuickLinkButton = styled(IconButton)(({ theme, color }) => ({
  backgroundColor: color,
  borderRadius: '50%',
  width: '50px',
  height: '50px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const IconWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
});

const QuickLinks = ({ onNotesClick, onRosterClick, onAgendaClick }) => {
  const navigate = useNavigate();

  const handleQuickShiftClick = () => {
    navigate('/admin/calendar');
  };

  const quickLinks = [
    { icon: <Assignment />, label: 'Diensten Uitvragen', color: '#FFE0B2', onClick: handleQuickShiftClick },
    { icon: <EventNote />, label: 'Notities', color: '#E1BEE7', onClick: onNotesClick },
    { icon: <RosterIcon />, label: 'Rooster', color: '#C8E6C9', onClick: onRosterClick },
    { icon: <CalendarToday />, label: 'Agenda', color: '#BBDEFB', onClick: onAgendaClick },
  ];

  return (
    <Grid container spacing={2} justifyContent="space-between">
      {quickLinks.map((link, index) => (
        <Grid item xs={3} key={index}>
          <Box display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%' }}>
            <QuickLinkButton color={link.color} onClick={link.onClick}>
              <IconWrapper>
                {link.icon}
              </IconWrapper>
            </QuickLinkButton>
            <Typography 
              variant="caption" 
              sx={{ 
                mt: 1, 
                width: '100%', 
                textAlign: 'center', 
                whiteSpace: 'nowrap', 
                overflow: 'hidden', 
                textOverflow: 'ellipsis' 
              }}
            >
              {link.label}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default QuickLinks;