import React, { useState, useContext } from 'react';
import { Typography, Button, Box, TextField, MenuItem, FormControlLabel, Checkbox, Link } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addHours, setHours, setMinutes } from 'date-fns';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { AppContext } from '../../contexts/AppContext';

const shiftTypes = [
  'Nacht Receptionist',
  'Nacht Portier',
  'Nacht Manager',
  'Dag Receptionist',
  'Ontbijtmedewerker'
];

const QuickShiftRequest = () => {
  const { user, pricingSettings } = useContext(AppContext);
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    return setMinutes(setHours(date, 22), 45);
  });
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    return setMinutes(setHours(addHours(date, 8), 6), 45);
  });
  const [shiftType, setShiftType] = useState('Nacht Receptionist');
  const [isEmergency, setIsEmergency] = useState(false);

  const handleStartDateChange = (newDate) => {
    setStartDate(newDate);
    setEndDate(addHours(newDate, 8));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isWithin24Hours = (new Date().getTime() - startDate.getTime()) / (1000 * 60 * 60) < 24;

    try {
      await addDoc(collection(db, 'diensten'), {
        hotelId: user.hotelId,
        type: shiftType,
        start: startDate,
        end: endDate,
        status: isEmergency && isWithin24Hours ? 'pending-emergency' : 'pending',
        isEmergency: isEmergency && isWithin24Hours,
        createdAt: serverTimestamp(),
        urgentFee: isEmergency && isWithin24Hours ? pricingSettings.urgentFee : 0
      });

      alert('Dienst succesvol aangevraagd!');
    } catch (error) {
      console.error('Fout bij het aanvragen van de dienst:', error);
      alert('Er is een fout opgetreden bij het aanvragen van de dienst.');
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="h6" gutterBottom>Snelle Dienst Aanvraag</Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          label="Startdatum en -tijd"
          value={startDate}
          onChange={handleStartDateChange}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
        <DateTimePicker
          label="Einddatum en -tijd"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </LocalizationProvider>
      <TextField
        select
        label="Type Dienst"
        value={shiftType}
        onChange={(e) => setShiftType(e.target.value)}
        fullWidth
      >
        {shiftTypes.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <FormControlLabel
        control={
          <Checkbox
            checked={isEmergency}
            onChange={(e) => setIsEmergency(e.target.checked)}
          />
        }
        label="Nooddienst"
      />
      {isEmergency && (
        <Typography variant="body2" color="error">
          Let op: Voor nooddiensten binnen 24 uur geldt een speciaal tarief volgens de contractuele afspraken.
        </Typography>
      )}
      <Button type="submit" variant="contained" fullWidth>
        Dienst Aanvragen
      </Button>
      <Typography variant="body2" align="center" sx={{ mt: 2 }}>
        Wil je meer diensten tegelijk uitvragen?{' '}
        <Link href="/agenda" underline="hover">
          Ga naar de agenda pagina
        </Link>
      </Typography>
    </Box>
  );
};

export default QuickShiftRequest;
