import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Paper, Grid } from '@mui/material';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { auth, db } from '../firebase';

const AdminSettings = () => {
  const [newAdminEmail, setNewAdminEmail] = useState('');
  const [newAdminPassword, setNewAdminPassword] = useState('');

  const handleCreateAdmin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, newAdminEmail, newAdminPassword);
      const user = userCredential.user;

      await setDoc(doc(db, 'users', user.uid), {
        email: newAdminEmail,
        role: 'admin',
        createdAt: new Date(),
      });

      setNewAdminEmail('');
      setNewAdminPassword('');
      alert('New admin account created successfully!');
    } catch (error) {
      console.error('Error creating admin account:', error);
      alert('Error creating admin account. Please try again.');
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Admin Settings
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Create New Admin Account
            </Typography>
            <form onSubmit={handleCreateAdmin}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                value={newAdminEmail}
                onChange={(e) => setNewAdminEmail(e.target.value)}
                margin="normal"
                required
              />
              <TextField
                fullWidth
                label="Password"
                type="password"
                value={newAdminPassword}
                onChange={(e) => setNewAdminPassword(e.target.value)}
                margin="normal"
                required
              />
              <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                Create Admin Account
              </Button>
            </form>
          </Paper>
        </Grid>
        {/* Add more settings sections here */}
      </Grid>
    </Box>
  );
};

export default AdminSettings;
