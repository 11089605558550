import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Rating, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import { db, auth } from '../firebase';
import { collection, setDoc, doc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';

const HotelCreationForm = ({ onClose }) => {
  const [hotel, setHotel] = useState({
    name: '',
    email: '',
    location: '',
    rate: '',
    imageUrl: '',
    contactPerson: '',
    stars: 0,
    serviceTypes: [],
    color: '#000000',
  });

  const serviceTypeOptions = [
    'Night Receptionist',
    'Day Receptionist',
    'Night Porter',
    'Night Manager'
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHotel(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const password = uuidv4().substring(0, 8);

      const userCredential = await createUserWithEmailAndPassword(auth, hotel.email, password);
      const user = userCredential.user;

      const hotelId = user.uid;

      await setDoc(doc(db, "users", user.uid), {
        ...hotel,
        email: hotel.email,
        hotelId,
        id: user.uid,
        role: 'hotel',
        uid: user.uid,
        createdAt: new Date(),
      });

      console.log("Hotel account created successfully");
      console.log("Email:", hotel.email);
      console.log("Password:", password);
      alert(`Hotel account created successfully.\nEmail: ${hotel.email}\nPassword: ${password}\nPlease save these credentials.`);
      onClose();
    } catch (error) {
      console.error("Error adding hotel: ", error);
      alert("Er is een fout opgetreden bij het aanmaken van het hotel account.");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Hotel Name"
            name="name"
            value={hotel.name}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Hotel Email"
            type="email"
            name="email"
            value={hotel.email}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Hotel Address"
            name="location"
            value={hotel.location}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Hotel Rate"
            type="number"
            name="rate"
            value={hotel.rate}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Image URL"
            name="imageUrl"
            value={hotel.imageUrl}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Contact Person"
            name="contactPerson"
            value={hotel.contactPerson}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="legend">Stars</Typography>
          <Rating
            name="stars"
            value={hotel.stars}
            onChange={(event, newValue) => {
              setHotel(prev => ({ ...prev, stars: newValue }));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Service Types</InputLabel>
            <Select
              multiple
              name="serviceTypes"
              value={hotel.serviceTypes}
              onChange={handleChange}
              renderValue={(selected) => selected.join(', ')}
            >
              {serviceTypeOptions.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Hotel Color"
            type="color"
            name="color"
            value={hotel.color}
            onChange={handleChange}
            required
          />
        </Grid>
      </Grid>
      <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
        Create Hotel
      </Button>
    </Box>
  );
};

export default HotelCreationForm;
