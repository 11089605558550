import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Avatar, Box, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AppContext } from '../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import theme from '../theme';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const Logo = styled('img')(({ theme }) => ({
  height: '40px',
  marginRight: '20px',
  [theme.breakpoints.down('sm')]: {
    height: '25px',
    marginRight: '10px',
  },
}));

const TopBar = () => {
  const { user, setUser } = useContext(AppContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <StyledAppBar position="fixed">
      <Toolbar>
        <Logo src="https://flexxhospitality.com/wp-content/uploads/2021/03/Asset-10@3x.png" alt="Flexx Hospitality" />
        <Box sx={{ flexGrow: 1 }} /> {/* This ensures the user icon is pushed to the right */}
        {user && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              {user.profilePictureUrl ? (
                <Avatar src={user.profilePictureUrl} alt={user.name} />
              ) : (
                <AccountCircle />
              )}
              <ExpandMoreIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => { handleClose(); navigate('/settings'); }}>Instellingen</MenuItem>
              <MenuItem onClick={handleLogout}>Uitloggen</MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </StyledAppBar>
  );
};

export default TopBar;
