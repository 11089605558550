import React from 'react';
import { Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, useMediaQuery, useTheme } from '@mui/material';
import AccountCard from './AccountCard';
import AccountTableRow from './AccountTableRow';

const AccountList = ({ filteredAccounts, viewMode, setEditingAccount, setOpenDialog, fetchAccounts, setSnackbar, handleDeleteAccount }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    viewMode === 'card' ? (
      <Grid container spacing={3}>
        {filteredAccounts.map(account => (
          <Grid item xs={12} sm={6} md={4} key={account.id}>
            <AccountCard 
              account={account}
              setEditingAccount={setEditingAccount}
              setOpenDialog={setOpenDialog}
              fetchAccounts={fetchAccounts}
              setSnackbar={setSnackbar}
            />
          </Grid>
        ))}
      </Grid>
    ) : (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Profielfoto</TableCell>
              <TableCell>Naam</TableCell>
              {!isMobile && <TableCell>E-mail</TableCell>}
              <TableCell>Rol</TableCell>
              <TableCell>Tarief</TableCell>
              <TableCell>Acties</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAccounts.map(account => (
              <AccountTableRow 
                key={account.id}
                account={account}
                setEditingAccount={setEditingAccount}
                setOpenDialog={setOpenDialog}
                handleDeleteAccount={handleDeleteAccount}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
};

export default AccountList;
