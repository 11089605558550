import React, { useContext } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { Home, Event, People, Settings } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppContext } from '../contexts/AppContext';

const BottomNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(AppContext);

  const getNavItems = () => {
    if (!user) return []; // Return empty array if user is not loaded yet

    switch (user.role) {
      case 'admin':
        return [
          { label: 'Home', icon: <Home />, path: '/admin' },
          { label: 'Shifts', icon: <Event />, path: '/admin/manage-shifts' },
          { label: 'Personnel', icon: <People />, path: '/admin/personnel-manager' },
          { label: 'Settings', icon: <Settings />, path: '/admin/settings' },
        ];
      case 'hotel':
        return [
          { label: 'Home', icon: <Home />, path: '/hotel' },
          { label: 'Agenda', icon: <Event />, path: '/hotel/agenda' },
          { label: 'Users', icon: <People />, path: '/hotel/manage-users' },
          { label: 'Settings', icon: <Settings />, path: '/hotel/settings' },
        ];
      case 'user':
        return [
          { label: 'Home', icon: <Home />, path: '/user' },
          { label: 'Shifts', icon: <Event />, path: '/user/shifts' },
          { label: 'Settings', icon: <Settings />, path: '/settings' },
        ];
      default:
        return [];
    }
  };

  const navItems = getNavItems();

  if (navItems.length === 0) return null; // Don't render BottomNav if there are no items

  return (
    <Paper 
      sx={{ 
        position: 'fixed', 
        bottom: 0, 
        left: 0, 
        right: 0, 
        zIndex: 1300
      }} 
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={navItems.findIndex(item => item.path === location.pathname)}
        onChange={(event, newValue) => {
          navigate(navItems[newValue].path);
        }}
      >
        {navItems.map((item, index) => (
          <BottomNavigationAction key={index} label={item.label} icon={item.icon} />
        ))}
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNav;
