import React from 'react';
import { Typography, Box } from '@mui/material';

const UserDashboard = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4">User Dashboard</Typography>
      <Typography variant="body1">Welcome to your dashboard!</Typography>
    </Box>
  );
};

export default UserDashboard;
