import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button } from '@mui/material';

const HotelRatesManagement = () => {
  const [hotels, setHotels] = useState([]);

  useEffect(() => {
    fetchHotels();
  }, []);

  const fetchHotels = async () => {
    const hotelsCollection = collection(db, 'users');
    const hotelSnapshot = await getDocs(hotelsCollection);
    const hotelList = hotelSnapshot.docs
      .filter(doc => doc.data().role === 'hotel')
      .map(doc => ({
        id: doc.id,
        ...doc.data(),
        rate: doc.data().rate || 0
      }));
    setHotels(hotelList);
  };

  const handleRateChange = (id, newRate) => {
    setHotels(hotels.map(hotel => 
      hotel.id === id ? { ...hotel, rate: newRate } : hotel
    ));
  };

  const saveRates = async () => {
    for (const hotel of hotels) {
      const hotelRef = doc(db, 'users', hotel.id);
      await updateDoc(hotelRef, { rate: parseFloat(hotel.rate) });
    }
    alert('Tarieven opgeslagen');
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Hoteltarieven beheer</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Hotel naam</TableCell>
              <TableCell>Tarief per uur (€)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hotels.map((hotel) => (
              <TableRow key={hotel.id}>
                <TableCell>{hotel.name}</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    value={hotel.rate}
                    onChange={(e) => handleRateChange(hotel.id, e.target.value)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="contained" onClick={saveRates} sx={{ mt: 2 }}>
        Tarieven opslaan
      </Button>
    </Box>
  );
};

export default HotelRatesManagement;
